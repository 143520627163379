import styled from 'styled-components'

export const BannerWrapper = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  color: #454a54;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: 100%;
  height: 4.4rem;
  padding: 0 1.5rem 0 1rem;

  background: rgba(255, 206, 50, 0.15);
  border: 1px solid rgba(255, 206, 50, 0.6);
  border-radius: 10px;

  @media (max-width: 800px) {
    margin: 0 0 1rem;
  }

  .left {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
  }
`

export const BannerContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const BannerTitle = styled.div`
  color: #1e63ff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;

  text-transform: uppercase;
`
