import { formatMoney, tr } from 'mmfintech-commons'
import { ProgressBar } from 'mmfintech-portal-commons'
import { SpendingLimitBarWrapper, SpentAmount } from './styles/SpendingBar.styled'
import { selectMerchantCurrency, useAppSelector } from 'mmfintech-backend-api'

type SpendingLimitBarProps = {
  totalAmount: number
  spentMonthlyVolume: number
}

const SpendingLimitBar = ({ totalAmount, spentMonthlyVolume }: SpendingLimitBarProps) => {
  const merchantCurrency = useAppSelector(selectMerchantCurrency)

  return (
    <SpendingLimitBarWrapper>
      <SpentAmount>
        <div className='available-amount'>
          <span className='available-text'>
            {tr('FRONTEND.EXPENSE_MANAGEMENT.DASHBOARD.MONEY_SPENT', 'Money spent:')}{' '}
            {formatMoney(spentMonthlyVolume, merchantCurrency)}
          </span>
        </div>
        <div className='total-amount'>{formatMoney(totalAmount, merchantCurrency)}</div>
      </SpentAmount>
      <ProgressBar value={spentMonthlyVolume} max={totalAmount} />
    </SpendingLimitBarWrapper>
  )
}

export default SpendingLimitBar
