import styled from 'styled-components'

import EmptyIcon from '../../images/icons/checkbox-group-empty.svg'
import CheckedIcon from '../../images/icons/checkbox-group-checked.svg'

export const CheckBoxGroupWrapper = styled.div`
  background: #ffffff;
  border-radius: 0.8rem;
  width: 100%;

  color: #1a1a1a;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`

export const CheckBoxGroupFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 2rem;
`

export const CheckBoxGroupHeader = styled.div`
  font-weight: 500;
  margin-bottom: 1rem;
`

export const CheckBoxGroupItem = styled.div`
  width: 100%;
  height: 4.4rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdfe3;
  white-space: nowrap;

  :last-child {
    border-bottom: none;
  }

  .checkbox {
    cursor: pointer;
    display: inline-block;

    width: 20px;
    height: 20px;
    margin-left: 4rem;

    //prettier-ignore
    background-image: url("${EmptyIcon}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.checked {
      //prettier-ignore
      background-image: url("${CheckedIcon}");
    }
  }
`
