import backButtonIcon from './images/icons/back-arrow-grey.svg'
import backButtonIconHover from './images/icons/back-arrow-black.svg'
import backButtonIconSecondaryHover from './images/icons/back-arrow-white.svg'

import radioSelected from './images/icons/radio-selected.svg'
import radioUnselected from './images/icons/radio-unselected.svg'

const bankyColors = {
  black: '#000000',
  grey: '#828282',
  radicalRed: '#ff4c4d',
  royalBlue: '#1e63ff',
  royalBlue75: 'rgba(30, 124, 255, 1)',
  whisper: '#e5e5e5',
  white: '#ffffff'
}

const theme = {
  radioSelected,
  radioUnselected,

  fontName: 'Rubik',

  containerBorderRadius: '1rem',
  resultQrCodeMargin: '0 1rem',

  header: {
    height: '6rem',
    heightThin: '4rem'
  },

  progressBar: {
    background: '#d9d9d9',
    colorGreen: 'rgba(68, 204, 0, 0.5)',
    colorRed: 'rgba(255, 49, 49, 0.5)'
  },

  colors: bankyColors,

  toast: {
    error: {
      iconTheme: { primary: 'red', secondary: 'white' },
      style: {
        color: bankyColors.white,
        fontSize: '1.6rem',
        lineHeight: '1.9rem',
        background: bankyColors.radicalRed,
        borderRadius: '1rem'
      }
    },
    success: {
      iconTheme: { primary: 'green', secondary: 'white' },
      style: {
        color: bankyColors.white,
        fontSize: '1.6rem',
        lineHeight: '1.9rem',
        background: '#7cc670',
        borderRadius: '1rem'
      }
    }
  },

  arrowButton: {
    width: '16px',
    height: '12px',
    textColor: '#939ea7',
    hoverColor: bankyColors.grey,
    secondaryHoverColor: bankyColors.grey
  },

  button: {
    loadingType: 'ThreeDots',
    primary: {
      height: '4.4rem',
      background: bankyColors.royalBlue,
      border: 'none',
      borderRadius: '1rem',
      boxShadow: '0px 0px 10px 0px #9FC6FF',
      color: bankyColors.white,
      fontSize: '1.6rem',
      fontWeight: '500',
      iconColor: bankyColors.white,
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: bankyColors.royalBlue75,
      hoverBorder: 'none',
      hoverBoxShadow: '0px 0px 10px 0px #9FC6F',
      hoverColor: bankyColors.white,

      disabledColor: bankyColors.white,
      disabledBackground: 'rgba(30, 99, 255, 0.30)', //bankyColors.royalBlue30
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    secondary: {
      height: '4.4rem',
      background: bankyColors.white,
      border: 'none',
      borderRadius: '1rem',
      boxShadow: 'none',
      color: '#A3B1BF',
      fontSize: '1.6rem',
      fontWeight: '500',
      iconColor: bankyColors.royalBlue75,
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: bankyColors.white,
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: bankyColors.royalBlue,

      disabledColor: bankyColors.white,
      disabledBackground: '#d5dbe2',
      disabledBorder: '1px solid #d5dbe2',
      disabledBoxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)'
    },
    alternative: {
      height: '4.4rem',
      background: '#ffce32',
      border: 'none',
      borderRadius: '1rem',
      boxShadow: 'none',
      color: bankyColors.white,
      fontSize: '1.6rem',
      fontWeight: '500',
      iconColor: bankyColors.white,
      iconSpacing: '1rem',
      padding: '0 1rem',

      hoverBackground: '#ffd44c',
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: bankyColors.white,

      disabledColor: bankyColors.white,
      disabledBackground: '#d5dbe2',
      disabledBorder: 'none',
      disabledBoxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)'
    },
    'round-primary': {
      height: '4rem',
      background: bankyColors.white,
      border: 'none',
      borderRadius: '2rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: bankyColors.black,
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#fec72b',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: '#f9f9fb',
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: bankyColors.grey,

      disabledColor: bankyColors.grey,
      disabledBackground: bankyColors.whisper,
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    'round-secondary': {
      height: '4rem',
      background: bankyColors.white,
      border: 'none',
      borderRadius: '2rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: bankyColors.black,
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#fec72b',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: '#338aff',
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: bankyColors.white,

      disabledColor: bankyColors.grey,
      disabledBackground: bankyColors.whisper,
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    delete: {
      height: '4rem',
      background: bankyColors.white,
      border: 'none',
      borderRadius: '2rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: '#2f80ed',
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#fec72b',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: bankyColors.white,
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: '#2f80ed',

      disabledColor: bankyColors.grey,
      disabledBackground: bankyColors.whisper,
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    }
  },

  checkbox: {
    checkedIconBackgroundColor: bankyColors.royalBlue,
    checkedIconBorderColor: bankyColors.white,
    iconBackground: bankyColors.white,
    iconBorder: '1px solid #dadada',
    iconBorderRadius: '5px',
    iconSize: '22px',
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textColor: '#454a54'
  },

  contentWrapper: {
    backgroundColor: '#f9f9fb',
    boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
    textColor: bankyColors.black,
    staticColor: '#939ea7',
    gridRowGap: '2rem',
    gridRowGap2: '4rem',
    gridRowGap3: '6rem'
  },

  datepicker: {
    container: {
      backgroundColor: bankyColors.white,
      borderColor: '#e1e5ea',
      borderRadius: '1rem',
      boxShadow: 'none', //0 5px 30px rgba(172, 182, 191, 0.25)
      fontSize: '1.2rem',
      padding: '0 0 1rem'
    },
    dayName: {
      fontSize: '1.2rem',
      fontWeight: '500',
      lineHeight: '2.6rem',
      textColor: bankyColors.black,
      marginTop: '2rem',
      marginBottom: '-8px'
    },
    header: {
      arrowSize: '2px',
      arrowTop: '1.5rem',
      arrowLeftRight: '1rem',
      backgroundColor: bankyColors.white,
      borderColor: bankyColors.white,
      fontSize: '1.4rem',
      fontWeight: '500',
      lineHeight: '2.6rem',
      textColor: bankyColors.black,
      padding: '2rem 2rem 0'
    },
    cells: {
      border: '1px solid transparent',
      borderRadius: '50%',
      height: '3rem',
      width: '3rem',
      fontSize: '1.2rem',
      fontWeight: '400',
      textColor: bankyColors.grey,
      outsideColor: '#a3b1bf',
      margin: '2px 4px',
      hoverBackground: 'transparent',
      hoverBorder: '1px solid #b4bfcb',
      hoverTextColor: bankyColors.black,
      selectedBackground: bankyColors.royalBlue,
      selectedBorder: '1px solid ' + bankyColors.royalBlue,
      selectedColor: bankyColors.white
    }
  },

  dataTable: {
    body: {
      backgroundColor: bankyColors.white,
      border: 'none',
      borderColor: bankyColors.whisper,
      textColor: bankyColors.black,
      fontSize: '1.6rem',
      fontWeight: '500',
      dropShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      infoLineColor: '#939ea7'
    },
    head: {
      background: '#f2f2f3',
      // border: '2px solid ' + bankyColors.whisper,
      textColor: '#000000',
      fontSize: '1.6rem',
      fontWeight: '500'
    },
    hover: {
      backgroundColor: '#f0f7ff',
      border: '1px solid ' + bankyColors.whisper,
      textColor: bankyColors.black
    }
  },

  dropTarget: {
    background: 'rgba(245, 181, 0, 0.8)',
    border: 'none',
    borderRadius: '2rem',
    textColor: bankyColors.black,
    fontSize: '1.6rem',
    fontWeight: 'normal'
  },

  errorDisplay: {
    borderColor: bankyColors.radicalRed,
    borderBottomColor: bankyColors.radicalRed,
    textColor: bankyColors.radicalRed,
    fontSize: '1.2rem',
    fontWeight: 'normal',
    lineHeight: 'normal',
    margin: '1rem 0'
  },

  finalMessage: {
    fontSize: '1.6rem',
    fontWeight: '500',
    textColor: bankyColors.black,
    infoTextColor: '#2f80ed',
    dangerTextColor: '#ee2b2b',
    successTextColor: '#59b300',
    padding: '0 2.5rem 4.5rem',
    contentPadding: '2.5rem 0 0'
  },

  gridTable: {
    border: '1px solid ' + bankyColors.whisper,
    fontSize: '1.4rem',
    fontSize2: '1.6rem',
    fontWeight: '500',
    lineHeight: '1.7rem',
    lineHeight2: '2rem',
    labelColor: '#85a0ad',
    valueColor: bankyColors.black,
    negativeColor: '#ee2b2b',
    positiveColor: '#59b300',
    rowPadding: '1rem 0',
    totalFontSize: '2rem',
    totalFontSize2: '2.4rem',
    totalFontWeight: 'bold',
    totalLineHeight: '2.4rem',
    totalLineHeight2: '3rem'
  },

  input: {
    label: {
      textColor: '#454a54',
      fontSize: '1.2rem',
      fontWeight: '500',
      lineHeight: '1.4rem',
      textTransform: 'none',
      marginBottom: '5px',
      marginLeft: '0',
      asteriskColor: bankyColors.radicalRed,
      asteriskMargin: '0',
      asteriskPosition: 'right',
      paddingWithIcon: '2.4rem',
      paddingRequired: '1.7rem'
    },
    error: {
      textColor: bankyColors.radicalRed,
      padding: '0',
      marginTop: '0.8rem'
    },
    wrapper: {
      marginBottom: '2rem'
    },
    container: {
      textColor: '#454a54',
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '1.7rem',
      background: bankyColors.white,
      border: '1px solid #e1e6ea',
      borderWidth: '1px',
      borderRadius: '1rem',
      padding: '0 1.6rem',
      iconColor: '#a3b1bf',
      errorBorderColor: bankyColors.radicalRed,
      errorBorderWidth: '1px',
      focusedBorderColor: 'rgba(30, 99, 255, 0.3)',
      focusedBorderWidth: '2px'
    },
    placeholder: {
      textColor: '#a3b1bf',
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '1.7rem'
    },
    dropDown: {
      controlBorder: 'none',
      controlBorderRadius: '0',
      controlPadding: '0',
      indicatorColor: '#a3b1bf',
      menuBackground: bankyColors.white,
      menuBorder: 'none',
      menuBorderRadius: '0 0 1rem 1rem',
      menuMargin: '0',
      menuPadding: '0 0 8px',
      openedBackground: 'transparent',
      openedBorder: 'none',
      openedBorderRadius: '0',
      openedColor: '#454a54',
      optionBorderRadius: '0',
      optionColor: '#454a54',
      optionFontSize: '1.4rem',
      optionFontWeight: 'normal',
      optionLineHeight: '1.7rem',
      optionMargin: '0',
      optionPadding: '1rem 2rem',
      optionBackground: bankyColors.white,
      focusedOptionColor: bankyColors.black,
      focusedOptionBackground: '#eff8e6', // rgba(163, 177, 191, .1)
      buttonColor: '#eff8e6',
      buttonTextAlign: 'center',
      buttonBorderRadius: '3px',
      buttonBackground: '#1a1a1a'
    },
    settings: {
      height: '4.4rem',
      singleSelect: false,
      autofillBackground: bankyColors.white,
      dropDownArrowColor: bankyColors.black,
      textareaMargin: '0',
      textareaPadding: '1.6rem 0',
      hideLabelWhenEmpty: false,
      showPasswordWithText: false
    }
  },

  loader: {
    spinnerColor: bankyColors.grey,
    spinnerSize: 55,
    spinnerType: 'TailSpin',
    loaderColor: bankyColors.grey,
    loaderSize: 75
  },

  noElementsMessage: {
    background: bankyColors.white,
    border: 'none',
    borderRadius: '1rem',
    boxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)',
    minHeight: '15rem',
    padding: '2rem',
    textColor: '#b4bfcb',
    fontSize: '1.8rem',
    fontWeight: '400',
    lineHeight: '2.1rem'
  },

  pagination: {
    marginPages: 2,
    minPagesVisible: 0,
    noArrowButtons: true,
    showPageLabel: true,
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '1.7rem',
    textColor: '#454a54',
    backgroundColor: 'transparent',
    buttonBorderColor: 'transparent',
    buttonRadius: '1rem',
    buttonMargin: '1rem',
    buttonSize: '3rem',
    buttonTextColor: '#454a54',
    buttonHoverBorderColor: 'transparent',
    buttonHoverTextColor: bankyColors.royalBlue,
    buttonActiveBackground: bankyColors.white,
    buttonActiveBorderRadius: '1rem',
    buttonActiveBoxShadow: '0px 5px 30px rgba(172, 182, 191, 0.25)',
    buttonActiveTextColor: bankyColors.royalBlue
  },

  radioButton: {
    iconSize: '2.4rem',
    iconSpacing: '1.6rem',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '2rem',
    textColor: bankyColors.grey,
    loadingColor: bankyColors.grey,
    loadingSize: '30'
  },

  switchInput: {
    width: '4.6rem',
    height: '2.6rem',
    border: 'none',
    borderRadius: '2.3rem',

    color: bankyColors.white,
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '120%',

    ballSize: '2.2rem',
    ballBackground: bankyColors.white,
    ballBoxShadow: '0 2px 2px rgba(0, 0, 0, 0.24)',

    checkedBackground: 'rgba(74, 209, 27, 0.7)',
    uncheckedBackground: bankyColors.whisper,

    uncheckedBallColor: bankyColors.white,
    uncheckedBorder: 'none',

    disabledColor: '#4f4f4f',
    disabledBallColor: bankyColors.white,
    disabledBorder: 'transparent',
    disabledBackground: 'rgba(74, 209, 27, 0.3)',
    disabledOpacity: '1'
  },
  backButtonIcon,
  backButtonIconHover,
  backButtonIconSecondary: backButtonIcon,
  backButtonIconSecondaryHover
}

export default theme
