import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { BankyLogo, LanguageMenu, LanguageSelectWrapper } from '../../../components'
import { LoginContainer, LoginContent, LoginForm, LoginLink, LoginSignup, LoginWrapper } from './Login.styled'

import { useAppDispatch } from '../../../hooks'
import { OtpContext, tr, useFormValues } from 'mmfintech-commons'
import { loggedIn, paths, useLoginReqMutation } from 'mmfintech-backend-api'

const Login = () => {
  const { setOtpOnSuccess } = useContext(OtpContext)

  const [login, { error: loginError, isLoading: loginFetching, reset: resetLoginReq }] = useLoginReqMutation()

  const dispatch = useAppDispatch()

  const formValues = useFormValues({
    email: { required: true, validation: 'email' },
    password: { required: true }
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOtpOnSuccess(() => (response: any) => {
      dispatch(loggedIn(response))
    })
    if (formValues.areValid()) {
      try {
        await login(formValues.prepare()).unwrap()
      } catch (error) {}
    }
  }

  const handleResetError = () => {
    if (loginError) {
      resetLoginReq()
    }
  }

  return (
    <LoginWrapper>
      <LoginContainer>
        <LanguageSelectWrapper>
          <LanguageMenu bordered />
        </LanguageSelectWrapper>

        <BankyLogo />

        <LoginContent>
          <div className='title'>{tr('FRONTEND.LOGIN.TITLE', 'Sign in to your account')}</div>

          <LoginForm noValidate onSubmit={handleSubmit}>
            <Input
              type='email'
              data-test='email'
              label={tr('FRONTEND.LOGIN.EMAIL', 'Email')}
              {...formValues.registerInput('email', handleResetError)}
              required={true}
              hideRequired={false}
              requiredPosition='right'
              disabled={loginFetching}
              autoFocus
            />

            <Input
              type='password'
              data-test='password'
              label={tr('FRONTEND.LOGIN.PASSWORD', 'Password')}
              {...formValues.registerInput('password', handleResetError)}
              required={true}
              hideRequired={false}
              requiredPosition='right'
              disabled={loginFetching}
              autoComplete='off'
              hideErrorLine
            />

            <LoginLink data-test='forgot-password'>
              <Link to={paths.forgotPassword()}>{tr('FRONTEND.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}</Link>
            </LoginLink>

            <ErrorDisplay error={loginError} />

            <Button
              type='submit'
              color='primary'
              text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Sign in')}
              loading={loginFetching}
              data-test='button-submit'
            />

            <LoginSignup>
              {tr('FRONTEND.LOGIN.NO_ACCOUNT', "Don't have an account?")}{' '}
              <Link to={paths.signUp()} data-test='sign-up'>
                <span>{tr('FRONTEND.LOGIN.SIGN_UP_FREE', 'Sign up for FREE')}</span>
              </Link>
            </LoginSignup>
          </LoginForm>
        </LoginContent>
      </LoginContainer>
    </LoginWrapper>
  )
}

export default Login
