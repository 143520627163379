import styled from 'styled-components'

export const AccountsDisplayStyled = styled.div`
  margin-top: 4.5rem;
`

export const AccountsDisplayHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .title {
    color: #454a54;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    //margin: 3.4rem 0 2rem 0;
  }

  .view-all {
    cursor: pointer;
    color: #454a54;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    svg {
      margin-left: 1rem;
    }
  }
`

export const AccountDisplayBody = styled.div`
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
`
