import styled from 'styled-components'

export const InfoCell = styled.div`
  display: block;

  .label {
    font-weight: 500;
  }

  @media (min-width: 720px) {
    text-align: right;
  }

  @media (min-width: 900px) {
    display: inline-block;
    text-align: left;

    :after {
      content: '|';
      padding: 0 1rem;
      color: rgba(163, 177, 191, 0.5);
    }

    :last-child:after {
      content: none;
    }
  }
`
