import React from 'react'

import { paths } from 'mmfintech-backend-api'

import Login from './views/auth/Login'
import Accounts from './views/account/Accounts'
import Expenses from './views/requests/Requests'
import Dashboard from './views/dashboard/Dashboard'
import Transactions from './views/transactions'

const SumSubVerification = React.lazy(() => import('./views/onboarding/SumSubVerification'))
const TransactionFinalStatus = React.lazy(() => import('./views/transactions/TransactionFinalStatus'))
const QuestionnaireKYB = React.lazy(() => import('./views/onboarding/QuestionnaireKYB'))

// prettier-ignore
export const nonProtectedRoutes = [
  // { path: paths.logout(), redirect: paths.login() },
  { path: paths.login(), Component: Login },
  { path: paths.signUp(), Component: React.lazy(() => import('./views/auth/Signup')) },
  { path: paths.checkEmail(), Component: React.lazy(() => import('./views/auth/VerifyEmailMessage')) },
  { path: paths.passwordReset(), Component: React.lazy(() => import('./views/auth/ResetPasswordMessage')) },
  { path: paths.forgotPassword(), Component: React.lazy(() => import('./views/auth/ForgotPassword')) },
  { path: paths.handleEmailVerify(), Component: React.lazy(() => import('./views/auth/VerifyEmail')) },
  { path: paths.changePasswordEmail(), Component: React.lazy(() => import('./views/auth/ForgotPasswordMessage')) },
  { path: paths.handlePasswordReset(), Component: React.lazy(() => import('./views/auth/ResetPassword')) },
  { path: paths.handleInvitationVerify(), Component: React.lazy(() => import('./views/auth/VerifyEmail')) },
  { path: paths.handlePasswordResetInvited(), Component: React.lazy(() => import('./views/auth/SetPassword')) },

  { path: paths.banking.boleto.fail(), exact: true, params: { isSuccess: false, method: 'boleto' }, Component: TransactionFinalStatus },
  { path: paths.banking.boleto.success(), exact: true, params: { isSuccess: true, method: 'boleto' }, Component: TransactionFinalStatus },
  { path: paths.banking.instantBankTransfer.fail(), exact: true, params: { isSuccess: false, method: 'direct_instant_bank_transfer' }, Component: TransactionFinalStatus },
  { path: paths.banking.instantBankTransfer.success(), exact: true, params: { isSuccess: true, method: 'direct_instant_bank_transfer' }, Component: TransactionFinalStatus },
  { path: paths.banking.finalStatus.fail(':method'), exact: true, params: { isSuccess: false }, Component: TransactionFinalStatus },
  { path: paths.banking.finalStatus.success(':method'), exact: true, params: { isSuccess: true }, Component: TransactionFinalStatus }
]

// prettier-ignore
export const routes = [
  { path: paths.root(), redirect: paths.dashboard() },

  { path: paths.dashboard(), PrivateComponent: Dashboard },
  { path: paths.settings(), PrivateComponent: React.lazy(() => import('./views/settings/Settings')) },
  { path: paths.externalAccounts(), PrivateComponent: React.lazy(() => import('./views/settings/SettlementAccounts')) },
  { path: paths.scanAndPay(), PrivateComponent: React.lazy(() => import('./views/scanAndPay/ScanAndPay')) },

  { path: paths.developer(), PrivateComponent: React.lazy(() => import('./views/developer/Developer')) },
  { path: paths.userManagement(), PrivateComponent: React.lazy(() => import('./views/users/Users')) },

  { path: paths.banking.accounts.list(), PrivateComponent: Accounts },
  { path: paths.banking.deposit(), PrivateComponent: React.lazy(() => import('./views/banking/Deposit')) },
  { path: paths.banking.expenses(), PrivateComponent: Expenses },
  { path: paths.banking.exchange(), PrivateComponent: React.lazy(() => import('./views/banking/Exchange')) },
  { path: paths.banking.sendBatchPayment(), PrivateComponent: React.lazy(() => import('./views/banking/Payout/Batch/BatchPayment')) },
  { path: paths.banking.sendIntraAccountTransfer(), PrivateComponent: React.lazy(() => import('./views/banking/Withdraw/IntraAccountTransfer')) },
  { path: paths.payout.select(), PrivateComponent: React.lazy(() => import('./views/banking/Payout/Selection')) },
  { path: paths.payout.crypto(), PrivateComponent: React.lazy(() => import('./views/banking/Payout/Crypto')) },
  { path: paths.payout.bankTransfer(), PrivateComponent: React.lazy(() => import('./views/banking/Payout/BankTransfer')) },

  { path: paths.withdraw.select(), PrivateComponent: React.lazy(() => import('./views/banking/Withdraw/Selection')) },
  { path: paths.withdraw.crypto(), PrivateComponent: React.lazy(() => import('./views/banking/Withdraw/Crypto')) },
  { path: paths.withdraw.bankTransfer(), PrivateComponent: React.lazy(() => import('./views/banking/Withdraw/BankTransfer')) },

  { path: paths.ibanIssuingPreview(), PrivateComponent: React.lazy(() => import('./views/account/IbanRequestPreview')) },
  { path: paths.banking.transactions.details(':transactionId'), PrivateComponent: React.lazy(() => import('./views/transactions/TransactionDetails')) },
  { path: paths.banking.transactions.listByAccountId(':accountId'), PrivateComponent: Transactions },
  { path: paths.banking.transactions.list(), PrivateComponent: Transactions },

  { path: paths.recipients.details(':recipientId'), PrivateComponent: React.lazy(() => import('./views/recipients/RecipientDetails')) },
  { path: paths.recipients.list(), PrivateComponent: React.lazy(() => import('./views/recipients/Recipients')) },

  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, PrivateComponent: SumSubVerification },
  { path: paths.onboarding.questionnaireKyb(), exact: true, PrivateComponent: QuestionnaireKYB },
  { path: paths.onboarding.questionnaireKybSuccess(), exact: true, PrivateComponent:  React.lazy(() => import('./views/onboarding/KybSuccess')) },
  { path: paths.onboarding.sumSub.continue(), PrivateComponent: SumSubVerification, exact: true }
]
