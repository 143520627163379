import { isValidElement, useContext, useEffect, useState } from 'react'

import cn from 'classnames'

import { CurrencyIcon } from '../CurrencyIcon'
import { Button, InputLabel } from 'mmfintech-portal-commons'
import { AccountCreateModal } from '../../views/account'
import { ChevronDownIcon, ChevronUpIcon } from '../../icons'
import {
  AccountButtonStyled,
  AccountListStyled,
  AccountStyled,
  AddButtonWrapper,
  SelectAccountError,
  SelectAccountStyled,
  SelectAccountWrapper
} from './SelectAccount.styled'

import { isOwnerOrAdministrator, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'
import { formatMoney, GlobalContext, isValidArray, isValidString, tr, useDropDownMenu } from 'mmfintech-commons'

import { AccountBalanceOut, TradeableAsset } from 'mmfintech-commons-types'
import { ThreeDots } from 'react-loader-spinner'

type SelectAccountProps = {
  bordered?: boolean
  staticDisplay?: boolean // if set, static account display will be presented
  accounts?: AccountBalanceOut[] // list of accounts to select from
  error?: string // error message (if needed)
  filteredCurrencies?: TradeableAsset['currency'][]
  label?: string // label above the selection box
  showAddButton?: boolean // if set an "Add New Account" button will be added at the end of the list
  showAllAccounts?: boolean // if set, "All Account" option will be added at the beginning of the list
  selectedAccount?: AccountBalanceOut // selected account
  setSelectedAccount?: (account: AccountBalanceOut) => void // selected account setter
  //todo filteredCurrencies, // list of supported currencies for the add new account screen
  darkMode?: boolean
  required?: boolean
  loading?: boolean
  dataTest?: string
  extraSection?: any
  hasExchangeAssets?: boolean
}

export const SelectAccount = ({
  bordered = false,
  staticDisplay = false,
  accounts,
  error,
  label,
  showAddButton = false,
  showAllAccounts = false,
  selectedAccount,
  setSelectedAccount,
  darkMode,
  required,
  loading,
  dataTest,
  extraSection = null,
  hasExchangeAssets,
  filteredCurrencies
}: SelectAccountProps) => {
  const customerRole = useAppSelector(selectCurrentUserRole)
  const { modalShow } = useContext(GlobalContext)
  const [filteredAccounts, setFilteredAccounts] = useState([])
  const [preselectAccountId, setPreselectAccountId] = useState(0)
  const { visible, hideDropDown, toggleDropDown, setRef } = useDropDownMenu({
    useOptionalRef: true
  })

  const toggle = () => {
    if (!loading && !staticDisplay) {
      toggleDropDown()
    }
  }

  const handleSelect = (account?: AccountBalanceOut): void => {
    hideDropDown()
    setSelectedAccount(account)
  }

  const handleCreateSuccess = (_currency: string, accountId: number) => {
    setPreselectAccountId(Number(accountId))
  }

  const handleNewAccountClick = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto', overflow: 'none' },
      content: <AccountCreateModal filteredCurrencies={filteredCurrencies} onSuccess={handleCreateSuccess} />
    })
  }

  const { id: accountId, balance, currencyCode, name } = selectedAccount || {}

  useEffect(() => {
    const list = Array.isArray(accounts) ? accounts : []
    setFilteredAccounts(list)

    if (preselectAccountId) {
      const find = list.find(account => account.id === preselectAccountId)
      setPreselectAccountId(0)
      handleSelect(find)
    }
    // eslint-disable-next-line
  }, [accounts])

  return (
    <SelectAccountWrapper className={cn('select-account-wrapper', { dark: darkMode })}>
      <InputLabel label={label} required={required} requiredPosition='right' />
      <SelectAccountStyled data-test={dataTest || 'select-account'}>
        <AccountButtonStyled
          className={cn({ bordered, static: staticDisplay, error: error?.length })}
          ref={visible ? setRef : null}
          onClick={toggle}>
          {loading ? (
            <span className='loading'>
              <ThreeDots height='12px' color='#1e63ff' />
            </span>
          ) : selectedAccount ? (
            <span className='account-info'>
              <span className='icon'>
                <CurrencyIcon currency={currencyCode} size='26px' />
              </span>
              <span className='content'>
                {accountId ? (
                  <>
                    <span>
                      {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ACCOUNT_NUM_LABEL', 'Account №')} {accountId}
                    </span>
                    <span className='balance'>
                      {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.BALANCE_LABEL', 'Balance')}:{' '}
                      {formatMoney(balance, currencyCode)}
                    </span>
                  </>
                ) : (
                  <span>{name}</span>
                )}
              </span>
            </span>
          ) : (
            <span className='no-account'>
              {showAllAccounts
                ? tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ALL_ACCOUNTS', 'All accounts')
                : tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.PLACEHOLDER', 'Select account')}
            </span>
          )}
          {!staticDisplay && <span className='chevron'>{visible ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>}
        </AccountButtonStyled>

        {!staticDisplay && (
          <AccountListStyled opened={visible} data-test='accounts-menu-content'>
            {showAllAccounts && (
              <AccountStyled data-test='all-accounts-menu-item' onClick={() => handleSelect(null)}>
                <span className='icon' />
                <span className='content'>
                  <span className='balance'>
                    {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ALL_ACCOUNTS', 'All accounts')}
                  </span>
                </span>
              </AccountStyled>
            )}
            {!isValidArray(accounts) && (
              <AccountStyled data-test='all-accounts-menu-item' className='disabled'>
                <span className='icon'>
                  <span className='icon-all'>N/A</span>
                </span>
                <span className='content'>
                  <span className='balance'>
                    <b>
                      {hasExchangeAssets
                        ? tr(
                            'FRONTEND.COMPONENTS.SELECT_ACCOUNT.CANNOT_EXCHANGE',
                            'Currently this asset cannot be exchanged'
                          )
                        : tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.NO_ACCOUNTS', 'No Eligible Accounts')}
                    </b>
                  </span>
                </span>
              </AccountStyled>
            )}

            {filteredAccounts?.map(account => {
              const { id, currencyCode, balance } = account

              return (
                <AccountStyled key={id} onClick={() => handleSelect(account)} data-test='account-menu-item'>
                  <span className='icon'>
                    <CurrencyIcon currency={currencyCode} size='26px' />
                  </span>
                  <span className='content'>
                    <span>
                      {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ACCOUNT_NUM_LABEL', 'Account №')} {id}
                    </span>
                    <span className='balance'>
                      {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.BALANCE_LABEL', 'Balance')}:{' '}
                      {formatMoney(balance, currencyCode)}
                    </span>
                  </span>
                </AccountStyled>
              )
            })}

            {showAddButton && isOwnerOrAdministrator(customerRole) && (
              <AddButtonWrapper>
                <Button
                  type='button'
                  color='round-secondary'
                  text={tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ADD_BUTTON', 'Add new account')}
                  onClick={handleNewAccountClick}
                />
              </AddButtonWrapper>
            )}
            {isValidElement(extraSection) ? extraSection : null}
          </AccountListStyled>
        )}
      </SelectAccountStyled>

      {isValidString(error) && <SelectAccountError>{error}</SelectAccountError>}
    </SelectAccountWrapper>
  )
}
