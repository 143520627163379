import { useContext } from 'react'

import { Button } from 'mmfintech-portal-commons'
import { configuration } from 'mmfintech-backend-api'
import { tr, GlobalContext } from 'mmfintech-commons'

import ManageCookiesBanky from './ManageCookiesBanky'

import { ConsentContent, ConsentFooter, CookieConsentWrapper } from './styled/cookieConsent.styled'

import CookieIcon from '../../images/icons/cookie.svg'

const CookieConsent = ({ setCookie }) => {
  const { modalShow } = useContext(GlobalContext)

  const onAcceptAllClick = () => {
    setCookie(
      'cookie.consent',
      JSON.stringify({
        necessaryCookies: true,
        optionalCookies: true
      }),
      {
        days: 365,
        maxAge: 31536000,
        domain: configuration.isLocal() ? null : '.banky.io'
      }
    )
  }

  const onManageCookiesClick = () => {
    modalShow({
      options: { maxWidth: 940, size: 'large' },
      content: <ManageCookiesBanky setCookie={setCookie} />
    })
  }

  return (
    <CookieConsentWrapper>
      <ConsentContent>
        <div>
          <div className='title'>{tr('FRONTEND.COOKIE_CONSENT.COOKIE_POLICY', 'Cookies Policy')}</div>
          {tr(
            'FRONTEND.COOKIE_CONSENT.PERSONALISED_XP',
            'We use cookies to personalize your experience on Banky portal. They are all about to personalize content and ads, provide social media features and to analyze our traffic.'
          )}
        </div>
        <img src={CookieIcon} alt='' />
      </ConsentContent>

      <ConsentFooter>
        <Button
          className='manage'
          color='round-primary'
          text={tr('FRONTEND.COOKIE_CONSENT.MANAGE_COOKIES', 'Manage cookies')}
          onClick={onManageCookiesClick}
          data-test='button-manage-cookies'
        />
        <Button
          className='consent'
          color='round-secondary'
          text={tr('FRONTEND.COOKIE_CONSENT.ACCEPT_ALL', 'Accept all')}
          onClick={onAcceptAllClick}
          data-test='button-accept-all'
        />
      </ConsentFooter>
    </CookieConsentWrapper>
  )
}

export default CookieConsent
