import styled from 'styled-components'

export const NoAvailableAccountsWrapper = styled.div`
  border: 1px solid rgba(133, 160, 173, 0.2);
  border-radius: 4px;

  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;

  .left {
    display: flex;
    align-items: center;
    gap: 2rem;
    color: #85a0ad;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .button {
    max-width: 17.5rem;
    height: 4.4rem;
    background: #ffffff;
    box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
    border-radius: 10px;

    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 400;
    &:hover {
      background: #ffffff;
      color: #000000;
    }
  }
`
