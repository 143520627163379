import styled from 'styled-components'

import BackgroundImage from '../../images/banky_bg.jpg'

export const CommonSignupWrapper = styled.div`
  grid-row: 1 / 4;
  grid-column: left-column / right-column;

  min-height: 100vh;
  padding: 10rem 2rem 0;
  @media (min-width: 950px) {
    padding: 7rem 2rem 0;
  }

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  background-color: #e5e5e5;
  //prettier-ignore
  background-image: url("${BackgroundImage}");
  background-position: -10rem bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 1024px) {
    //prettier-ignore
    background-image: url("${BackgroundImage}");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const CommonSignupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 0 0 6.5rem;

  .err-msg-wrapper {
    display: flex;
    justify-content: center;
  }

  .logo-container {
  }
`

export const CommonContainer = styled.div`
  width: 92vw;
  max-width: 50rem;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
  .success-container {
    padding: 3rem 5rem 3.5rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .subtitle {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 2.2rem;
      margin-bottom: 2rem;
    }

    img {
      margin-bottom: 2rem;
    }

    button {
      max-width: 18rem;
      margin-top: 1rem;
    }
  }
`

export const CommonSignupContent = styled(CommonContainer)`
  padding: 2rem;
  @media (min-width: 450px) {
    padding: 4rem 5rem;
  }

  color: #454a54;
  font-family: inherit;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;

  .title {
    color: #454a54;
    font-family: inherit;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.8rem;

    margin-bottom: 3rem;

    .subtitle {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7rem;
      margin-top: 1rem;
    }
  }
`

export const CommonSignupForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  a {
    color: #1e63ff;
  }
`

export const LanguageSelectWrapper = styled.div`
  position: absolute;
  right: 5rem;
  top: 3rem;
`

export const CommonPageTitle = styled.div`
  color: #000000;
  font-family: inherit;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;

  .subtitle {
    margin-top: 0.5rem;
    color: #828282;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
  }
`

export const BasePageWrapper = styled.div`
  grid-area: main-content;
  padding: 3rem 2rem 2rem;

  display: flex;
  flex-flow: column nowrap;

  @media (min-width: 1175px) {
    padding: 5rem 2rem 2rem;
  }
`

export const BasePageTitle = styled.div`
  color: #454a54;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-bottom: 2rem;
  text-align: center;

  @media (min-width: 540px) {
    font-size: 3.6rem;
    margin-bottom: 3rem;
  }
`

export const BaseSubtitle = styled.div`
  color: #454a54;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 2rem;

  @media (min-width: 540px) {
    font-size: 2.4rem;
    margin-bottom: 3rem;
  }
`

export const BaseBoxWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 100%;
  max-width: 50rem;
`

export const BaseBoxContainer = styled.div`
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
  padding: 3rem 2rem;
  width: 100%;

  @media (min-width: 540px) {
    padding: 4rem 5rem;
  }
`
