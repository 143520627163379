import styled from 'styled-components'

export const HeaderIconsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  gap: 1.5rem;
  margin: 0 0.8rem;

  svg {
    cursor: pointer;
  }
`

export const DropDownMenuContainer = styled.div`
  //left: -14rem;
  right: -1rem;
  position: absolute;
  width: 20rem;
  z-index: 1001;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  padding: 1rem 0;

  &.settings {
    top: 3rem;
  }

  .menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid transparent;

    width: auto;
    white-space: nowrap;

    height: 1rem;
    padding: 2rem 2.2rem;
    text-align: center;
    text-decoration: none;

    color: #454a54;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.7rem;

    :hover {
      border: 2px solid transparent;
      color: #454a54;
      background: #e7ebee;
    }

    &.selector {
      margin: 0 1rem;
      &:first-of-type {
        margin-top: 0.5rem;
      }
      :hover {
        border-radius: 4px;
      }
    }
    &.logout {
      margin: 2rem 3.5rem 3rem;
      justify-content: center;
      color: #ffffff;
      background: #1e63ff;
      padding: 1.5rem 1rem;
      border-radius: 4px;
    }
  }
`
