import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  .icon {
    padding: 0 1rem 0 0;
  }
  .content {
    flex: 1;
    padding: 0 1rem;
    display: flex;
    flex-flow: column nowrap;
    span > div {
      margin: 0;
      justify-content: initial;
    }
    .label {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }
  .close-icon {
    cursor: pointer;
  }
`
