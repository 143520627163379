import styled from 'styled-components'
import { BasePageWrapper } from '../../components'

export const DashboardWrapper = styled(BasePageWrapper)``

export const DashboardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  margin-bottom: 2rem;
  grid-auto-flow: row;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr auto;
    align-items: center;

    &.extended {
      grid-template-columns: 1fr auto;
    }
  }
`

export const HeaderBalanceStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;

  @media (min-width: 800px) {
    flex-flow: row nowrap;
  }
`

export const DashboardGraphContainer = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr;
  width: 100%;

  @media (min-width: 800px) {
    grid-template-columns: 1fr 30rem;
  }
`

export const AccountBalance = styled.div`
  width: 100%;
  height: 4.4rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  color: #454a54;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .label {
    color: #a3b1bf;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4px;
  }
`

const DashboardButtons = styled.div`
  .button {
    background: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

    height: 4.4rem;
    padding: 0 1.7rem;
    margin-bottom: 1rem;

    color: #454a54;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    :hover {
      color: #454a54;
      background: #ffffff;
    }

    @media (min-width: 800px) {
      width: auto;
      min-width: 12rem;
      margin-bottom: 0;
      padding: 0 1.5rem;
      justify-content: flex-start;
    }

    .icon.icon-left {
      margin-right: 1.2rem;
    }
  }
`

export const AccountButtons = styled(DashboardButtons)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  .button {
    margin: 1rem 0.5rem 0;
    width: auto;
  }

  @media (min-width: 800px) {
    flex-flow: row nowrap;
    .button {
      margin: 1rem 1rem 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`

export const StaffDashboardWrapper = styled.div``

export const StaffDashboardHeader = styled(DashboardButtons)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  margin-bottom: 2rem;

  @media (min-width: 800px) {
    flex-flow: row nowrap;
    align-items: center;
  }

  .button {
    max-width: 14rem;
  }
`
