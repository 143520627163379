import { Link } from 'react-router-dom'

import { LogoWrapper } from './BankyLogo.styled'
import { paths } from 'mmfintech-backend-api'

import LogoImage from '../../images/banky-logo-blue.png'

export const BankyLogo = () => (
  <LogoWrapper>
    <Link to={paths.root()}>
      <img src={LogoImage} alt='Logo' />
    </Link>
  </LogoWrapper>
)
