import { useHistory } from 'react-router-dom'

import SumSubBanner from '../onboarding/SumSubBanner'

import { Button } from 'mmfintech-portal-commons'
import { TotalsSection } from './TotalsSection'
import { DashboardChart } from './DashboardChart'
import { AccountsSection } from './AccountsSection'
import { ExchangeIcon, DepositIcon, WithdrawIcon, PaymentLinkIcon, PayoutIcon } from '../../icons'
import {
  AccountBalance,
  AccountButtons,
  DashboardGraphContainer,
  DashboardHeader,
  HeaderBalanceStyled
} from './Dashboard.styled'

import { formatMoney, isValidArray, tr } from 'mmfintech-commons'
import {
  paths,
  selectMerchantCapabilities,
  useAppSelector,
  useGetAggregatedBalanceQuery,
  useMerchantAccounts
} from 'mmfintech-backend-api'

export const DashboardAdmin = () => {
  const capabilities = useAppSelector(selectMerchantCapabilities)

  const { accounts: paymentAccounts } = useMerchantAccounts()
  const { balance } = useGetAggregatedBalanceQuery(
    {
      dateFrom: '',
      dateTo: ''
    },
    {
      selectFromResult: ({ data }) => ({
        balance: isValidArray(data) ? data[data.length - 1] : null
      }),
      refetchOnMountOrArgChange: true
    }
  )

  const history = useHistory()

  const { enablePortalWalletCheckout } = capabilities || {}

  return (
    <>
      <SumSubBanner className='mb-2' />

      <DashboardHeader className={enablePortalWalletCheckout ? 'extended' : ''}>
        <HeaderBalanceStyled>
          {isValidArray(paymentAccounts) && (
            <AccountBalance>
              <span className='label'>{tr('FRONTEND.DASHBOARD.TOTAL_BALANCE', 'Total account balance')}</span>
              {formatMoney(balance?.amount, balance?.currencyCode)}
            </AccountBalance>
          )}
        </HeaderBalanceStyled>

        <AccountButtons>
          {isValidArray(paymentAccounts) ? (
            <Button
              color='alternative'
              icon={<DepositIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_DEPOSIT', 'Deposit')}
              onClick={() => history.push(paths.banking.deposit())}
              data-test='button-deposit'
            />
          ) : null}
          {isValidArray(paymentAccounts) ? (
            <Button
              color='alternative'
              icon={<WithdrawIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_WITHDRAW', 'Withdraw')}
              onClick={() => history.push(paths.withdraw.select())}
              data-test='button-withdraw'
            />
          ) : null}
          <Button
            color='alternative'
            icon={<ExchangeIcon />}
            text={tr('FRONTEND.DASHBOARD.BUTTON_EXCHANGE', 'Exchange')}
            onClick={() => history.push(paths.banking.exchange())}
            data-test='button-exchange-link'
          />
          {enablePortalWalletCheckout && (
            <Button
              color='alternative'
              icon={<PaymentLinkIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_PAYMENT_LINK', 'Payment link')}
              onClick={() => history.push(paths.scanAndPay())}
              data-test='button-payment-link'
            />
          )}
          {isValidArray(paymentAccounts) ? (
            <Button
              color='alternative'
              icon={<PayoutIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_PAYOUT', 'Payout')}
              onClick={() => history.push(paths.payout.select())}
              data-test='button-payout'
            />
          ) : null}
        </AccountButtons>
      </DashboardHeader>

      <DashboardGraphContainer>
        <DashboardChart />
        <TotalsSection />
      </DashboardGraphContainer>

      <AccountsSection
        accounts={paymentAccounts}
        onClick={(id: number) => history.push(paths.banking.transactions.listByAccountId(id))}
      />
    </>
  )
}
