import { useContext, useEffect } from 'react'

import toast from 'react-hot-toast'

import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import {
  AccountCreateForm,
  AccountCreateTitle,
  AccountCreateWrapper
} from '../AccountCreateModal/AccountCreateModal.styled'

import { GlobalContext, tr } from 'mmfintech-commons'
import { useAccountEditQuery } from 'mmfintech-backend-api'

export const AccountUpdateModal = ({ account, onSuccess }) => {
  const { modalHide } = useContext(GlobalContext)

  const { id: accountId, name: accountName } = account || {}

  const handleSuccessfulChange = () => {
    toast.remove()
    toast.success(tr('FRONTEND.ACCOUNTS.UPDATE.SUCCESS', 'Account successfully updated'))
    onSuccess()
    modalHide()
  }

  const { formValues, error, loading, handleSubmit, resetMutation } = useAccountEditQuery({
    accountId,
    accountName,
    onSuccess: handleSuccessfulChange
  })

  useEffect(() => {
    formValues.handleErrors(error)
    // eslint-disable-next-line

    return () => {
      resetMutation()
    }
    //eslint-disable-next-line
  }, [error])

  return (
    <AccountCreateWrapper data-test='create-account-modal'>
      <AccountCreateTitle className='title mb-2'>
        {tr('FRONTEND.ACCOUNTS.UPDATE.TITLE', 'Edit account')}
      </AccountCreateTitle>

      <AccountCreateForm noValidate>
        <Input
          type='text'
          data-test='account-name'
          label={tr('FRONTEND.ACCOUNTS.CREATE.NAME_LABEL', 'Account name')}
          {...formValues.registerInput('name')}
          autoFocus
        />

        <ErrorDisplay error={error} />

        <Button
          type='button'
          color='primary'
          text={tr('FRONTEND.BUTTONS.SAVE', 'Save')}
          loading={loading}
          data-test='button-submit'
          onClick={handleSubmit}
        />
      </AccountCreateForm>
    </AccountCreateWrapper>
  )
}
