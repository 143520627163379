import styled from 'styled-components'

export const AccountWarningWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  color: #ff4c4d;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;

  width: 100%;
  padding: 2rem;
`

export const WarningHeaderWrapper = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;

  display: flex;
  align-items: center;
  justify-items: flex-start;

  color: #ff4c4d;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;

  img,
  svg {
    margin-right: 1rem;
  }
`
