import styled from 'styled-components'

export const PayoutModalWrapper = styled.div`
  width: 100vw;
  max-width: 50rem;

  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
  padding: 4rem 5rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 3rem;

  h3 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 400;

    margin: 0;
  }

  .amount-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    align-items: center;
    width: 100%;

    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.9rem;
  }

  .info-container {
    width: 100%;
  }
  .row {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    font-size: 14px;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    .label {
      font-weight: 600;
    }
    .value {
      color: #a3b1bf;
    }

    @media (min-width: 660px) {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      .label {
        flex: 2;
      }
      .value {
        flex: 3;
      }
    }
  }
`
export const PayoutPreviewContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  width: 100%;
  margin-top: 2rem;
  font-size: 1.4rem;

  .amount {
    color: #000000;
    font-family: inherit;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.9rem;
  }

  .fee {
    color: #a3b8c2;
    font-family: inherit;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: normal;
    line-height: 1.5rem;
  }
`
