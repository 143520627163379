import styled from 'styled-components'

export const SuggestTransferModalWrapper = styled.div`
  font-size: 1.6rem;
  padding: 4rem 5rem;
  display: grid;

  min-width: 50rem;
  border-radius: 8px;
  @media (max-width: 565px) {
    max-width: 40rem;
    min-width: 40rem;
    padding: 2rem;
  }

  .title {
    font-size: 2.4rem;
    line-height: 2.9rem;

    text-align: center;
  }
  .body {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #333333;
    margin-top: 2rem;
  }
  .suggest-actions {
    margin: 2rem 0;
    padding: 0 1.5rem;
  }

  .buttons-container {
    margin-top: 2rem;
    padding: 0 1.5rem;

    button:first-of-type {
      font-weight: 400;
      margin-bottom: 1rem;
    }
  }
`
