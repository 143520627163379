import cn from 'classnames'
import { CurrencyIconWrapper } from './CurrencyIcon.styled'
import { getCurrencyImageUrl } from 'mmfintech-commons'
import { configuration } from 'mmfintech-backend-api'

export const CurrencyIcon = ({ currency, size = '3rem', circle = false, className = '' }) => (
  <CurrencyIconWrapper size={size} className={cn(className, { circle })}>
    <img src={`${configuration.getBaseUrl()}${getCurrencyImageUrl(currency, 'svg')}`} alt='' />
  </CurrencyIconWrapper>
)
