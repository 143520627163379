import styled from 'styled-components'

export const UploadedFileWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  height: 6.3rem;
  background-color: rgba(68, 204, 0, 0.02);
  border: 1px dashed rgba(0, 102, 255, 0.5);
  border-radius: 8px;
  min-height: 12rem;

  padding: 1.6rem 2.5rem;
`

export const UploadedFileContent = styled.div`
  display: flex;
  align-items: center;

  font-size: 1.2rem;
  line-height: 1.5rem;
`

export const UploadedFileName = styled.div`
  display: flex;
  flex-flow: column nowrap;

  margin-left: 0.5rem;

  .label {
    color: #44cc00;
    word-wrap: break-word;
  }
  .file-name {
    color: #85a0ad;
    white-space: normal !important;
    word-break: break-all !important;
    overflow-wrap: anywhere !important;
  }
`

export const UploadedFileDeleteIcon = styled.div`
  cursor: pointer;
`

export const UploadWrapper = styled.div`
  min-height: 12rem;
  padding: 0 2rem;
  cursor: pointer;

  background-color: rgba(0, 102, 255, 0.05);
  border: 1px dashed rgba(133, 160, 173, 0.5);
  border-radius: 5px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;

  color: #85a0ad;
  font-size: 1.2rem;
  font-weight: 400;

  div {
    display: flex;
    flex-flow: column nowrap;
  }
  span {
    margin-bottom: 0.5rem;
  }

  &.disabled {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: default;
    #Vector,
    #Vector_2,
    #Vector_3 {
      stroke: #85a0ad;
    }
  }
`
