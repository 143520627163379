import { formatMoney, tr } from 'mmfintech-commons'

import { LabelValue } from 'mmfintech-portal-commons'
import { PayoutPreviewContent } from './PayoutModal.styled'

export const PayoutPreview = ({ previewData, checkoutDetails }) => {
  const { fee, feeCurrency, walletAmount, walletCurrency } = previewData || {}
  const { customerName, customerEmail } = checkoutDetails || {}

  return (
    <>
      <h3>{tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.PREVIEW_TITLE', 'Payout preview')}</h3>

      <PayoutPreviewContent>
        <LabelValue
          label={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.CUSTOMER_NAME', 'Customer name')}
          value={customerName}
          colon
          dataTest='customer-name'
        />
        <LabelValue
          label={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.CUSTOMER_EMAIL', 'Customer email')}
          value={customerEmail}
          colon
          dataTest='customer-email'
        />
        <LabelValue
          label={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.AMOUNT', 'Amount')}
          value={walletAmount > 0 && walletCurrency?.length ? formatMoney(walletAmount, walletCurrency) : null}
          colon
          dataTest='net-amount'
        />
        {fee > 0 ? (
          <LabelValue
            label={`${tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.FEE', 'Fee')}:`}
            value={formatMoney(fee, feeCurrency)}
            colon
            dataTest='fee-amount'
          />
        ) : null}
      </PayoutPreviewContent>
    </>
  )
}
