import styled from 'styled-components'

import ExportIcon1 from '../../images/icons/export-csv-green.png'
import ExportIcon2 from '../../images/icons/export-csv-yellow.png'
import ExportIcon3 from '../../images/icons/export-csv-blue.png'

export const TransactionsWrapper = styled.div`
  grid-column: left-column / right-column;
  width: 100%;
  padding: 2rem;
`

export const TransactionsHeader = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column-reverse nowrap;
  margin-bottom: 2rem;

  .button {
    background: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

    color: #454a54;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    min-width: 12rem;
    margin-bottom: 0;
    padding: 0 1.8rem;

    :hover {
      color: #454a54;
      background: #ffffff;
    }
  }

  .select-account-wrapper {
    width: 100%;
  }

  .left-side,
  .right-side {
    width: 100%;
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .left-side {
    flex-flow: column-reverse nowrap;

    .button {
      margin-bottom: 2rem;
    }
  }

  .right-side {
    gap: 1rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 540px) {
    .select-account-wrapper {
      width: 35rem;
    }

    .left-side,
    .right-side {
      flex-flow: row nowrap;
      align-items: flex-end;
      gap: 2rem;
    }

    .left-side {
      .button {
        width: auto;
        margin-bottom: 0;
      }
    }

    .right-side {
      flex-flow: row wrap;
      justify-content: center;
      width: 100%;
      .button {
        width: auto;
      }
    }
  }

  @media (min-width: 640px) {
    .button {
      width: auto;
    }

    .left-side,
    .right-side {
      width: auto;
      flex-flow: row nowrap;
    }

    .left-side {
    }

    .right-side {
      width: 100%;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-end;
      gap: 2rem;
    }
  }

  @media (min-width: 800px) {
    .right-side {
      width: 100%;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 2rem;
    }
  }

  @media (min-width: 1320px) {
    display: grid;
    grid-template-columns: auto 1fr;

    .button {
      width: auto;
      min-width: 12rem;
      margin-bottom: 0;
      padding: 0 1.8rem;
    }

    .right-side {
      width: auto;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }
`

export const NoTransactionsWrapper = styled.div`
  font-size: 20px;
  text-align: center;
  margin-top: 2rem;
`

export const TransactionsPaginationContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;

  @media (min-width: 1200px) {
    background: #ffffff;
    box-shadow: 0 5px 30px rgba(172, 182, 191, 0.3);
    border-radius: 1rem;
    margin-bottom: 2rem;

    .data-table-container {
      .data-table {
        box-shadow: none;
        border-bottom: 1px solid rgba(163, 177, 191, 0.15);
      }
    }
  }

  .transactions-pagination {
    padding: 1rem 0;
  }
`

export const TransactionsFooterContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media (min-width: 640px) {
    flex-flow: row nowrap;
    justify-content: flex-end;
  }
`

export const ExportButton = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2rem;

  color: #454a54;
  font-family: inherit;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem;

  @media (min-width: 640px) {
    margin-right: 3rem;
  }

  .icon {
    display: inline-block;
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 1rem;

    :after {
      position: absolute;
      left: -10px;
      top: -10px;
      right: -10px;
      bottom: -10px;
      content: '';
      //prettier-ignore
      background: url("${ExportIcon3}") center center no-repeat;
      background-size: contain;
    }

    &.export-pdf:after {
      //prettier-ignore
      background-image: url("${ExportIcon1}");
    }
    &.export-csv:after {
      //prettier-ignore
      background-image: url("${ExportIcon2}");
    }
  }
`
