import styled from 'styled-components'

export const ContentWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(320px, 1240px) 1fr;
  grid-template-rows: 6rem auto;
  grid-template-areas: 'header header header' 'left-column main-content right-column';

  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  font-family: 'Rubik', sans-serif;
  background-color: #f5f6fa;

  @media (min-width: 1175px) {
    grid-template-rows: 8.3rem auto;
  }

  ${'' /* react-datepicker styling */}
  .react-datepicker {
    background-color: #ffffff;
    border-color: #e1e5ea;
    border-radius: 1rem;
    box-shadow: none;
    font-size: 1.2rem;
    padding: 0 0 1rem;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__header {
    background-color: #ffffff;
    border-bottom-color: #ffffff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 2rem 2rem 0;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #000000;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.6rem;
  }
  .react-datepicker__day-names {
    margin-top: 2rem;
    margin-bottom: -8px;
  }
  .react-datepicker__day-name {
    color: #000000;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2.6rem;
    margin: 2px 4px;
    width: 3rem;
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .react-datepicker__navigation--next {
    top: 1.5rem;
    right: 1rem;
  }
  .react-datepicker__navigation--previous {
    top: 1.5rem;
    left: 1rem;
  }
  .react-datepicker__day {
    border: 1px solid transparent;
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 3rem;
    margin: 2px 4px;
    width: 3rem;
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-top-color: #e1e5ea;
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    border-top-color: #ffffff;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: #e1e5ea;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: #ffffff;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #000000;
    border-top-width: 2px;
    border-right-width: 2px;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: #1e63ff;
    border: 1px solid #1e63ff;
    color: #ffffff;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__month--selected:hover,
  .react-datepicker__month--in-selecting-range:hover,
  .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: transparent;
  }
  .react-datepicker__day--outside-month {
    color: #a3b1bf;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: #000000;
    background-color: transparent;
    border: 1px solid #b4bfcb;
  }
`
