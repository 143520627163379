import { ReactNode } from 'react'

import cn from 'classnames'
import { tr } from 'mmfintech-commons'

import {
  CheckBoxGroupFooter,
  CheckBoxGroupHeader,
  CheckBoxGroupItem,
  CheckBoxGroupWrapper
} from './CheckBoxGroup.styled'

type CheckBoxItem = {
  name?: string
  label?: string
  localizationKey?: string
  defaultVisible?: boolean
}

type CheckBoxGroupProps = {
  header?: ReactNode
  footer?: ReactNode
  items: CheckBoxItem[]
  checked: (name: string) => boolean
  setChecked: (name: string) => void
}

export const CheckBoxGroup = ({ footer, header, items, checked, setChecked }: CheckBoxGroupProps) => (
  <CheckBoxGroupWrapper onClick={e => e.stopPropagation()}>
    {header ? <CheckBoxGroupHeader>{header}</CheckBoxGroupHeader> : null}

    {Array.isArray(items) &&
      items.map((item, index) => {
        const { name, label, localizationKey } = item
        return (
          <CheckBoxGroupItem key={index}>
            <span>{tr(localizationKey, label)}</span>
            <span
              className={cn('checkbox', { checked: checked(name) })}
              onClick={() => setChecked(name)}
              data-test={`toggle-${name}`}
            />
          </CheckBoxGroupItem>
        )
      })}

    {footer ? <CheckBoxGroupFooter>{footer}</CheckBoxGroupFooter> : null}
  </CheckBoxGroupWrapper>
)
