import styled from 'styled-components'

export const MenuWrapper = styled.div`
  position: relative;

  color: #454a54;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;
  margin: 0 1rem;
  //padding: 0 0 0 2.2rem;

  &.hamburger {
    margin: 0;
  }

  .icon-letter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #699cfc;

    color: white;
    font-size: 14px;
    font-weight: 400;

    &.active {
      background-color: #3cd1a3;
    }
  }
`

export const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;

  //width: 100%;
  height: 3.6rem;

  .name {
  }

  .arrow {
    width: 11px;
    //margin: 0 1rem 0 1rem;
  }
`

export const MenuDropDown = styled.div`
  z-index: 1001;
  position: absolute;
  top: 4rem;
  right: -1rem;

  min-width: 20rem;
  padding: 1rem;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  display: none;
  &.opened {
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      cursor: pointer;
      display: flex;
      gap: 8px;
      align-items: center;
      height: 4rem;
      padding: 0 2rem 0 1rem;
      color: #454a54;
      white-space: nowrap;

      :hover {
        background: #e7ebee;
        border-radius: 0.5rem;
      }
    }
  }
`
