import { DashboardAdmin } from './DashboardAdmin'
import { DashboardStaff } from './DashboardStaff'
import { DashboardWrapper } from './Dashboard.styled'

import { useAppSelector, selectCurrentUserRole } from 'mmfintech-backend-api'

import { CustomerRoleEnum } from 'mmfintech-commons-types'

const Dashboard = () => {
  const customerRole = useAppSelector(selectCurrentUserRole)

  return (
    <DashboardWrapper>
      {customerRole === CustomerRoleEnum.STAFF ? <DashboardStaff /> : <DashboardAdmin />}
    </DashboardWrapper>
  )
}

export default Dashboard
