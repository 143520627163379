import { CurrencyIcon } from '../../../components'
import { AccountBalance, AccountCurrency, AccountButtonStyled, AccountInfo } from './AccountButton.styled'

import { formatFloat, getCurrencyDecimals } from 'mmfintech-commons'

import { AccountBalanceOut } from 'mmfintech-commons-types'

interface AccountButtonProps {
  account: AccountBalanceOut
  onClick: (accountId: number) => void
}

export const AccountButton = ({ account, onClick }: AccountButtonProps) => {
  const { id: accountId, currencyCode, name: accountName, type: accountType } = account

  const handleClick = () => {
    typeof onClick === 'function' && onClick(accountId)
  }

  return (
    <AccountButtonStyled onClick={handleClick}>
      <AccountCurrency>
        <CurrencyIcon currency={currencyCode} size='4.2rem' />
        <AccountBalance>
          <span>{account?.currencyCode}</span>
          <span className='balance'>{formatFloat(account?.balance, getCurrencyDecimals(account?.currencyCode))}</span>
        </AccountBalance>
      </AccountCurrency>
      <AccountInfo>
        <div className='account-name'>{accountName}</div>
        <div className='account-type'>{accountType}</div>
        <div className='account-id'>ID: {accountId}</div>
      </AccountInfo>
    </AccountButtonStyled>
  )
}
