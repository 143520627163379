import { useContext, useState } from 'react'

import DatePicker from 'react-datepicker'
import fileDownload from 'js-file-download'

import {
  endpoints,
  selectCurrentLanguage,
  useAppSelector,
  useFileDownloader,
  useGetMerchantQuery
} from 'mmfintech-backend-api'
import { Button, ErrorDisplay, Input, RadioButtonGroup } from 'mmfintech-portal-commons'
import {
  compareDates,
  currentDay,
  currentMonth,
  currentYear,
  firstDateOfMonth,
  fixDateOnly,
  formatDateLocal,
  GlobalContext,
  lastDayOfMonth,
  monthOptions,
  prepareDate,
  tr,
  useFormValues
} from 'mmfintech-commons'

import { SelectAccount } from '../../components'
import { DateContainer, DateWrapper, StatementWrapper, StyledForm } from './styled/balanceStatementModal.styled'

import { StatementPeriodTypeEnum } from 'mmfintech-commons-types'

import CalendarIcon from '../../images/icons/calendar.svg'

const BalanceStatementPdfModal = ({ account, accounts, dateFrom, dateTo }) => {
  const { modalHide } = useContext(GlobalContext)
  const { data: merchant } = useGetMerchantQuery()
  const selectedLanguage = useAppSelector(selectCurrentLanguage)

  const [error, setError] = useState(null)
  const [periodType, setPeriodType] = useState<keyof typeof StatementPeriodTypeEnum>(StatementPeriodTypeEnum.CUSTOM)
  const [selectedAccount, setSelectedAccount] = useState(account)

  const formValues = useFormValues({
    quarter: { value: 'Q1' },
    month: { value: currentMonth() + 1 },
    year: { value: currentYear() },
    startDate: { value: dateFrom ? dateFrom : firstDateOfMonth() },
    endDate: { value: dateTo ? dateTo : new Date() }
  })

  const downloader = useFileDownloader()

  //const fiscalMonths = ['M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8', 'M9', 'M10', 'M11', 'M12']
  const fiscalQuarters = ['Q1', 'Q2', 'Q3', 'Q4']

  const getYears = () => {
    const { createdOn } = merchant || {}
    const year2 = prepareDate(createdOn).getFullYear()
    const res = []
    for (let y = currentYear(); y >= year2; y--) {
      res.push(y)
    }
    return res
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!selectedAccount) {
      setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_ACCOUNT', 'Account is not selected.'))
      return
    }
    setError(null)

    let period: any

    switch (periodType) {
      case StatementPeriodTypeEnum.MONTHLY:
        const startDate = new Date(formValues.getValue('year'), formValues.getValue('month') - 1, 1)
        if (compareDates(startDate, new Date()) > 0) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        let endDate = lastDayOfMonth(startDate)
        if (endDate.getFullYear() === currentYear() && endDate.getMonth() === currentMonth()) {
          endDate.setDate(currentDay())
        }
        if (compareDates(endDate, new Date()) > 0) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        period = {
          periodType: StatementPeriodTypeEnum.CUSTOM,
          startDate: fixDateOnly(startDate),
          endDate: fixDateOnly(endDate)
        }
        // period = {
        //   periodType,
        //   month: formValues.getValue('month'), // [ M1, M2, M3, M4, M5, M6, M7, M8, M9, M10, M11, M12 ]
        //   year: formValues.getValue('year')
        // }
        break
      case StatementPeriodTypeEnum.QUARTERLY:
        period = {
          periodType,
          quarter: formValues.getValue('quarter'), // [ Q1, Q2, Q3, Q4 ]
          year: formValues.getValue('year')
        }
        break
      case StatementPeriodTypeEnum.YEARLY:
        period = {
          periodType,
          year: formValues.getValue('year')
        }
        break
      default:
        if (
          compareDates(formValues.getValue('startDate'), new Date()) > 0 ||
          compareDates(formValues.getValue('endDate'), new Date()) > 0
        ) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        period = {
          periodType: StatementPeriodTypeEnum.CUSTOM,
          startDate: fixDateOnly(formValues.getValue('startDate')),
          endDate: fixDateOnly(formValues.getValue('endDate'))
        }
    }

    const data = {
      accountId: selectedAccount.id,
      period: period
    }

    void downloader.download({
      url: endpoints.transactions.getStatementPDF(),
      method: 'POST',
      data,
      onSuccess: (data: any, filename) => {
        fileDownload(data, filename || 'balance-statement.pdf', 'application/pdf')
        modalHide()
      }
    })
  }

  return (
    <StatementWrapper data-test='balance-statement-period-modal'>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <div className='title'>{tr('FRONTEND.STATEMENT.EXPORT.TITLE', 'Balance Statement Period')}</div>

        <SelectAccount
          accounts={accounts}
          label={tr('FRONTEND.STATEMENT.EXPORT.ACCOUNT_LABEL', 'Account')}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />

        <RadioButtonGroup
          options={[
            {
              value: StatementPeriodTypeEnum.MONTHLY,
              label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_MONTHLY', 'Monthly'),
              dataTest: StatementPeriodTypeEnum.MONTHLY
            },
            // {
            //   value: statementPeriodType.QUARTERLY,
            //   label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_QUARTERLY', 'Quarterly'),
            //   dataTest: statementPeriodType.QUARTERLY
            // },
            // {
            //   value: statementPeriodType.YEARLY,
            //   label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_YEARLY', 'Yearly'),
            //   dataTest: statementPeriodType.YEARLY
            // },
            {
              value: StatementPeriodTypeEnum.CUSTOM,
              label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_CUSTOM', 'Custom'),
              dataTest: StatementPeriodTypeEnum.CUSTOM
            }
          ]}
          selected={periodType}
          setSelected={val => setPeriodType(val as keyof typeof StatementPeriodTypeEnum)}
        />

        {periodType === StatementPeriodTypeEnum.CUSTOM ? (
          <div className='period-wrap'>
            <DateContainer>
              <label>
                {tr('FRONTEND.STATEMENT.EXPORT.DATE_FROM_LABEL', 'From')}
                <span className='asterisk'>*</span>
              </label>
              <DatePicker
                locale={selectedLanguage}
                maxDate={new Date()}
                selected={formValues.getValue('startDate')}
                onChange={date => formValues.setValue('startDate', date)}
                calendarStartDay={1}
                customInput={
                  <DateWrapper>
                    <img src={CalendarIcon} alt='From' />
                    <span>{formatDateLocal(formValues.getValue('startDate'))}</span>
                  </DateWrapper>
                }
              />
            </DateContainer>
            <DateContainer>
              <label>
                {tr('FRONTEND.STATEMENT.EXPORT.DATE_TO_LABEL', 'To')}
                <span className='asterisk'>*</span>
              </label>
              <DatePicker
                locale={selectedLanguage}
                maxDate={new Date()}
                selected={formValues.getValue('endDate')}
                onChange={date => formValues.setValue('endDate', date)}
                calendarStartDay={1}
                customInput={
                  <DateWrapper>
                    <img src={CalendarIcon} alt='To' />
                    <span>{formatDateLocal(formValues.getValue('endDate'))}</span>
                  </DateWrapper>
                }
              />
            </DateContainer>
          </div>
        ) : (
          <div className='year-month'>
            <Input
              type='select'
              data-test='year'
              menuPosition='fixed'
              parentClassName='year-wrapper'
              label={tr('FRONTEND.STATEMENT.EXPORT.YEAR_LABEL', 'Year')}
              options={getYears().map(year => ({ label: year, value: year }))}
              {...formValues.registerInput('year')}
              required
            />

            {periodType === StatementPeriodTypeEnum.MONTHLY && (
              <Input
                type='select'
                data-test='month'
                menuPosition='fixed'
                label={tr('FRONTEND.STATEMENT.EXPORT.MONTH_LABEL', 'Month')}
                options={monthOptions()}
                {...formValues.registerInput('month')}
                required
              />
            )}

            {periodType === StatementPeriodTypeEnum.QUARTERLY && (
              <Input
                type='select'
                data-test='quarter'
                label={tr('FRONTEND.STATEMENT.EXPORT.QUARTER_LABEL', 'Fiscal quarter')}
                menuPosition='fixed'
                options={fiscalQuarters.map(q => ({ label: q, value: q }))}
                {...formValues.registerInput('quarter')}
              />
            )}
          </div>
        )}

        <ErrorDisplay error={[downloader.error, error]} />

        <Button
          type='submit'
          color='primary'
          text={tr('FRONTEND.STATEMENT.EXPORT.GENERATE_BUTTON', 'Generate Statement')}
          loading={downloader.fetching}
          data-test='button-generate'
        />
      </StyledForm>
    </StatementWrapper>
  )
}

export default BalanceStatementPdfModal
