import styled from 'styled-components'

export const AccountButtonStyled = styled.div`
  cursor: pointer;
  height: 18rem;
  width: 100%;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  padding: 2rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  color: #000;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 800px) {
    width: 22rem;
  }
`

export const AccountCurrency = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;

  margin-bottom: 2rem;
`

export const AccountBalance = styled.div`
  display: flex;
  flex-flow: column nowrap;

  color: #a3b1bf;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .balance {
    color: #454a54;
    font-weight: 600;
  }
`

export const AccountInfo = styled.div`
  .account-name {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .account-type {
    color: #454a54;
    margin-bottom: 1.5rem;
  }

  .account-id {
    background: #c3ccd5;
    border-radius: 3.7rem;
    padding: 0.4rem 1rem;
    width: fit-content;

    color: #fff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
