import { configuration, initialize } from 'mmfintech-backend-api'
import { registerLocale } from 'react-datepicker'

import es from 'date-fns/locale/es'
import ja from 'date-fns/locale/ja'
import hr from 'date-fns/locale/hr'
import pl from 'date-fns/locale/pl'
import pt from 'date-fns/locale/pt'

initialize(import.meta.env)

registerLocale('es', es)
registerLocale('ja', ja)
registerLocale('hr', hr)
registerLocale('pl', pl)
registerLocale('pt', pt)

initialize(import.meta.env)

const settings = {
  cookieDomain: '.banky.io',
  customerServiceUrl: 'mailto:support@banky.io',
  landingPageUrl: configuration.isDevelopment()
    ? 'https://dev.banky.io/'
    : configuration.isSandbox()
    ? 'https://sandbox.banky.io/'
    : 'https://banky.io',
  languages: ['en', 'es', 'ja', 'hr', 'pl', 'pt'],
  questionnaireFileTypes: ['pdf', 'jpg', 'jpeg', 'png'],
  questionnaireMaxFileSize: 1024 * 1024 * 10,
}

export default settings
