import styled from 'styled-components'

export const InputAmountLimitsStyled = styled.div`
  color: #85a0ad;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  margin-top: 1rem;
`
