import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import cn from 'classnames'

import { CurrencyBadge } from '../../../components'
import { SelectedAccount } from './SelectedAccount'
import { NoAvailableAccounts } from './NoAvailableAccounts'
import { Preloader, Button, ErrorDisplay } from 'mmfintech-portal-commons'
import {
  IbanRequestAccounts,
  IbanRequestBadges,
  IbanRequestButtons,
  IbanRequestFees,
  IbanRequestFeeTitle,
  IbanRequestHeading1,
  IbanRequestHeading2,
  IbanRequestHeading3,
  IbanRequestModalWrapper,
  IbanRequestTab,
  IbanRequestTabs
} from './IbanRequestModal.styled'

import { formatMoney, GlobalContext, isValidArray, isValidObject, isValidString, tr } from 'mmfintech-commons'
import {
  useGetBankAccountFeesQuery,
  useGetPaymentAccountsByCurrencyQuery,
  useGetSupportedSchemesAndCurrenciesQuery,
  useIbanRequestInitMutation,
  useIbanRequestPreviewMutation
} from 'mmfintech-backend-api'

import { AccountBalanceOut, BankAccountIssuingPreviewRequest } from 'mmfintech-commons-types'

export const IbanRequestModal = ({ reload }: { reload: () => void }) => {
  const { modalHide }: any = useContext(GlobalContext)
  const history = useHistory()

  const [previewInit, { error: ibanIssuingPreviewError, reset: resetPreview }] = useIbanRequestPreviewMutation({
    fixedCacheKey: 'iban-preview'
  })
  const [requestInit, { error: initError }] = useIbanRequestInitMutation()
  const { availableSchemes, ibanSchemesAndCurrenciesFetching, currencies, ibanScehemesAndCurrenciesSuccess } =
    useGetSupportedSchemesAndCurrenciesQuery(undefined, {
      selectFromResult: ({ data, isFetching, isSuccess }) => ({
        ibanScehemesAndCurrenciesSuccess: isSuccess,
        ibanSchemesAndCurrenciesFetching: isFetching,
        currencies: data?.currencies,
        availableSchemes: isValidObject(data?.currencies)
          ? (Object.keys(data.currencies) as BankAccountIssuingPreviewRequest['scheme'][]).filter(currency =>
              isValidArray(data.currencies[currency])
            )
          : []
      })
    })

  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [currentTab, setCurrentTab] = useState<BankAccountIssuingPreviewRequest['scheme']>(availableSchemes[0])

  const { filteredAccounts, accountsByCurrencyFetching } = useGetPaymentAccountsByCurrencyQuery(
    { currency: selectedCurrency },
    {
      refetchOnMountOrArgChange: true,
      skip: !selectedCurrency,
      selectFromResult: ({ data, isFetching }) => ({
        accountsByCurrencyFetching: isFetching,
        filteredAccounts: isValidArray(data)
          ? data.filter((account: AccountBalanceOut) => {
              if (account.ibanStatus) {
                return ['DECLINED', 'INITIAL'].includes(account.ibanStatus)
              }
              return true
            })
          : []
      })
    }
  )

  const { data: bankAccountFees, error: bankAccountFeesError } = useGetBankAccountFeesQuery(
    {
      currency: selectedCurrency,
      network: currentTab
    },
    {
      skip: !selectedCurrency || !currentTab,
      refetchOnMountOrArgChange: true
    }
  )

  const fees = isValidArray(bankAccountFees) ? [...bankAccountFees] : []
  const [selectedAccount, setSelectedAccount] = useState<AccountBalanceOut>()

  const handleChangeTab = (tabName: BankAccountIssuingPreviewRequest['scheme']) => {
    if (ibanIssuingPreviewError) {
      resetPreview()
    }
    if (tabName !== currentTab) {
      setCurrentTab(tabName)
      setSelectedCurrency(currencies[tabName][0])
      setSelectedAccount(undefined)
    }
  }

  const handleSetCurrency = (e: React.SyntheticEvent, currency: string) => {
    e.preventDefault()
    if (ibanIssuingPreviewError) {
      resetPreview()
    }
    if (currency !== selectedCurrency) {
      setSelectedCurrency(currency)
      setSelectedAccount(undefined)
    }
  }

  const handleRequestPreview = async (payload: any) => {
    const previewResponse = await previewInit(payload).unwrap()

    if (!previewResponse) return

    const issuingFee = fees?.find(f => f.feeType === 'IBAN_ISSUING_FEE')
    if (issuingFee.value > 0) {
      modalHide()
      history.push({
        pathname: '/iban-issuing/preview',
        state: { payload }
      })
    } else {
      return await handleRequestInit(payload)
    }
  }

  const handleRequestInit = async (payload: any) => {
    const initResponse = await requestInit(payload).unwrap()
    if (initResponse && 'status' in initResponse && initResponse.status === 'PROCESSED') {
      reload()
      modalHide()
    }
  }

  const handleRequest = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const payload = {
      currency: selectedCurrency,
      issuingAccountId: selectedAccount?.id,
      scheme: currentTab
    }
    try {
      await handleRequestPreview(payload)
    } catch (error) {
      return { error }
    }
  }

  useEffect(() => {
    if (isValidArray(availableSchemes)) {
      const firstScheme = availableSchemes[0]
      setCurrentTab(availableSchemes[0])
      setSelectedCurrency(currencies[firstScheme][0])
    }
    //eslint-disable-next-line
  }, [ibanScehemesAndCurrenciesSuccess])

  return (
    <IbanRequestModalWrapper>
      <IbanRequestHeading1>{tr('FRONTEND.IBAN_ISSUING.MODAL.TITLE', 'IBAN Issuing Request')}</IbanRequestHeading1>

      <IbanRequestHeading2>
        {tr('FRONTEND.IBAN_ISSUING.MODAL.INSTRUCTIONS', 'Choose a payment network and currency for your IBAN:')}
      </IbanRequestHeading2>

      <IbanRequestTabs>
        {ibanSchemesAndCurrenciesFetching ? (
          <Preloader />
        ) : (
          availableSchemes.map((scheme, index) => (
            <IbanRequestTab
              key={index}
              onClick={() => handleChangeTab(scheme)}
              className={cn({ active: currentTab === scheme })}
              data-test={`${scheme.toLowerCase()}-tab`}>
              {scheme}
            </IbanRequestTab>
          ))
        )}
      </IbanRequestTabs>

      <IbanRequestBadges>
        {isValidString(currentTab) &&
          currencies[currentTab]?.map((currency: string, index: number) => (
            <CurrencyBadge
              key={index}
              currencyCode={currency}
              isSelected={selectedCurrency === currency}
              onClick={(e: React.SyntheticEvent) => handleSetCurrency(e, currency)}
            />
          ))}
      </IbanRequestBadges>

      {isValidString(currentTab) && isValidString(selectedCurrency) && (
        <>
          <IbanRequestHeading2>
            {tr('FRONTEND.IBAN_ISSUING.MODAL.ASSOCIATE.TITLE', 'Choose a currency account to associate with the IBAN:')}
          </IbanRequestHeading2>
          <IbanRequestHeading3>
            {tr(
              'FRONTEND.IBAN_ISSUING.MODAL.ASSOCIATE.SUBTITLE',
              'If no selection is made, a new currency account will be created automatically.'
            )}
          </IbanRequestHeading3>

          <IbanRequestAccounts>
            {accountsByCurrencyFetching ? (
              <Preloader />
            ) : isValidArray(filteredAccounts) ? (
              <div className='inner'>
                {filteredAccounts?.map((acc: any) => (
                  <SelectedAccount
                    account={acc}
                    setAccount={(acc: any) => {
                      if (acc.id === selectedAccount?.id) {
                        setSelectedAccount(undefined)
                      } else {
                        setSelectedAccount(acc)
                      }
                    }}
                    isSelected={selectedAccount?.id === acc.id}
                    key={acc.id}
                  />
                ))}
              </div>
            ) : (
              <NoAvailableAccounts currency={selectedCurrency} />
            )}
          </IbanRequestAccounts>
        </>
      )}
      <div className='fees-container'>
        <IbanRequestHeading3>
          {tr(
            'FRONTEND.IBAN_ISSUING.MODAL.DISCLAIMER',
            'Please note that the Iban fee will be deducted from your account. You may need to update your existing or provide extra documents. Contact your customer support manager for more information regarding your request.'
          )}
        </IbanRequestHeading3>
        {isValidArray(fees) && isValidString(selectedCurrency) && (
          <IbanRequestFees>
            {fees
              .sort((a, b) => b.feeType.toLowerCase().localeCompare(a.feeType.toLowerCase()))
              .map((fee: any, index: number) => {
                const { feeType, feeCurrency, value } = fee
                return (
                  <IbanRequestFeeTitle key={index}>
                    <div>
                      {tr(
                        `FRONTEND.IBAN_ISSUING.${feeType}`,
                        feeType === 'IBAN_ISSUING_FEE' ? 'Issuing fee:' : 'Monthly Maintenance fee:'
                      )}
                    </div>
                    <div className='fee'>{formatMoney(value, feeCurrency)}</div>
                  </IbanRequestFeeTitle>
                )
              })}
          </IbanRequestFees>
        )}
      </div>

      <ErrorDisplay error={[ibanIssuingPreviewError, bankAccountFeesError, initError]} />

      <IbanRequestButtons>
        <Button
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          onClick={() => {
            modalHide()
            resetPreview()
          }}
          data-test='button-cancel'
        />
        <Button
          color='primary'
          text={tr('FRONTEND.BUTTONS.REQUEST', 'Request')}
          onClick={handleRequest}
          data-test='button-new-account'
          disabled={!isValidString(currentTab) || !isValidString(selectedCurrency)}
        />
      </IbanRequestButtons>
    </IbanRequestModalWrapper>
  )
}
