export const PayoutIcon = ({ color = '#1e63ff' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='25' viewBox='0 0 18 25' fill='none'>
    <path
      d='M17 10.5V8C17 7.17157 16.3284 6.5 15.5 6.5H2.5C1.67157 6.5 1 7.17157 1 8V17C1 17.8284 1.67157 18.5 2.5 18.5H9.5'
      stroke={color}
      strokeLinecap='round'
    />
    <path d='M3 15.0562H3.85714' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6.99982 15.0183L16.4033 15.0183' stroke={color} strokeLinecap='round' />
    <path
      d='M13.6194 11.4998L16.8016 15.0355L13.6194 18.5713'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
