import { useContext } from 'react'

import ChangeLimitModal from './ChangeLimitModal'
import SpendingLimitBar from './SpendingLimitBar'

import { LimitsWrapper } from './styles/Limits.styled'
import { Button, DataTable, Preloader, NoElementsMessage } from 'mmfintech-portal-commons'

import { useFetchExpenseLimitsQuery } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'

const LimitsTable = () => {
  const { modalShow }: any = useContext(GlobalContext)

  const { data: expenseLimits, isFetching } = useFetchExpenseLimitsQuery()

  const handleChangeLimit = (id: number, name: string, currency: string, monthlyVolume: number) => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto' },
      content: (
        <ChangeLimitModal
          merchantUserId={id}
          merchantUserName={name}
          currency={currency}
          monthlyVolume={monthlyVolume || 0}
        />
      )
    })
  }

  return (
    <LimitsWrapper>
      {isFetching ? (
        <Preloader />
      ) : isValidArray(expenseLimits) ? (
        <DataTable.Table scale1='600px' scale2='1200px' skipHover>
          <DataTable.Head>
            <DataTable.Row>
              <DataTable.HeadCell>{tr('FRONTEND.LIMITS.COLUMNS.NAME', 'Name')}</DataTable.HeadCell>
              <DataTable.HeadCell>{tr('FRONTEND.LIMITS.COLUMNS.LIMITS', 'Limits')}</DataTable.HeadCell>
              <DataTable.HeadCell />
            </DataTable.Row>
          </DataTable.Head>
          <DataTable.Body>
            {expenseLimits?.map((limit: any, index: number) => (
              <DataTable.Row key={index} data-test='table-row'>
                <DataTable.Cell data-test='limit-name'>
                  <div className='limit-name'>{limit?.merchantUserName}</div>
                </DataTable.Cell>
                <DataTable.Cell data-test='limit-value'>
                  <SpendingLimitBar totalAmount={limit?.monthlyVolume} spentMonthlyVolume={limit?.spentMonthlyVolume} />
                </DataTable.Cell>
                <DataTable.Cell className='preview-cell small'>
                  <Button
                    type='button'
                    color='primary'
                    data-test='details-button'
                    text={tr('FRONTEND.LIMITS.BUTTON_CHANGE', 'Change limit')}
                    onClick={() =>
                      handleChangeLimit(
                        limit?.merchantUserId,
                        limit?.merchantUserName,
                        limit?.currency,
                        limit?.monthlyVolume
                      )
                    }
                  />
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable.Body>
        </DataTable.Table>
      ) : (
        <NoElementsMessage message={tr('FRONTEND.LIMITS.NO_LIMITS_MESSAGE', 'No limits yet')} />
      )}
    </LimitsWrapper>
  )
}

export default LimitsTable
