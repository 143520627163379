import styled from 'styled-components'

export const ManageCookiesWrapper = styled.div`
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  width: 97vw;
  max-width: 70rem;

  padding: 4rem;

  display: flex;
  flex-direction: column;

  color: #454a54;
  font-family: inherit;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;

  h2 {
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.8rem;

    padding-top: 2rem;
    border-top: 1px solid rgba(180, 191, 203, 0.3);
    :first-of-type {
      padding-top: 0;
      border-top: none;
    }
  }
`

export const SwitchContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 7rem;
  grid-gap: 5rem;

  p {
    margin: 0 0 1rem;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  .button {
    max-width: 40rem;
  }
`
