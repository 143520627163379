import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import cn from 'classnames'

import { MenuContainer, MenuDropDown, MenuWrapper } from './LanguageMenu.styled'

import settings from '../../settings'

import { initializeLanguage, languages, storeLanguage, useDropDownMenu } from 'mmfintech-commons'
import {
  changeLanguage,
  selectCurrentLanguage,
  selectCurrentUserStatus,
  useAppSelector,
  useSetUserLanguageMutation
} from 'mmfintech-backend-api'

import { ChevronDownIcon, ChevronUpIcon } from '../../icons'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import EnglishIcon from '../../images/icons/language-icons/en-icon.svg?react'
import SpanishIcon from '../../images/icons/language-icons/es-icon.svg?react'
import JapaneseIcon from '../../images/icons/language-icons/ja-icon.svg?react'
import PolishIcon from '../../images/icons/language-icons/pl-icon.svg?react'
import CroatianIcon from '../../images/icons/language-icons/hr-icon.svg?react'
import PortugueseIcon from '../../images/icons/language-icons/pt-icon.svg?react'

const icons = {
  en: <EnglishIcon />,
  es: <SpanishIcon />,
  ja: <JapaneseIcon />,
  pl: <PolishIcon />,
  hr: <CroatianIcon />,
  pt: <PortugueseIcon />
}

type LanguageMenuProps = {
  hamburger?: boolean
  showIcons?: boolean
  bordered?: boolean
}

export const LanguageMenu = ({ hamburger = false, showIcons = false, bordered = false }: LanguageMenuProps) => {
  const userStatus = useAppSelector(selectCurrentUserStatus)
  const selectedLanguage = useAppSelector(selectCurrentLanguage)
  const [setLanguage] = useSetUserLanguageMutation()

  const [filteredLanguages, setFilteredLanguages] = useState([])

  const dispatch = useDispatch()

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const handleLanguageClick = (languageCode: string): void => {
    if (languageCode !== selectedLanguage) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        setLanguage({
          language: languageCode
        })
      }
      dispatch(changeLanguage(languageCode))
      storeLanguage(languageCode)
    }
    hideDropDown()
    initializeLanguage(languageCode)
  }

  const getLanguageName = (): string => {
    const data = filteredLanguages.find(item => item.code === selectedLanguage)
    return data ? data.name : ''
  }

  useEffect(() => {
    const filtered = settings.languages.map(code => languages.find(v => v.code === code)).filter(v => v != null)
    setFilteredLanguages(filtered)
    // eslint-disable-next-line
  }, [])

  if (!selectedLanguage) {
    return null
  }

  return (
    <MenuWrapper className={cn({ hamburger })} data-test='language-menu-wrapper'>
      <MenuContainer className={cn({ opened: visible, bordered })} onClick={toggleDropDown}>
        {showIcons ? <span className='icon'>{icons[selectedLanguage]}</span> : <span className='icon additional' />}
        <span className='selected'>{getLanguageName()}</span>
        <span className='arrow'>{visible ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
      </MenuContainer>
      <MenuDropDown className={cn({ opened: visible })} data-test='language-menu-content'>
        <ul data-test='language-menu-list'>
          {filteredLanguages?.map(v => (
            <li key={v.code} onClick={() => handleLanguageClick(v.code)} data-test='language-menu-item'>
              {showIcons && <span className='icon'>{icons[v.code]}</span>}
              {v.name}
            </li>
          ))}
        </ul>
      </MenuDropDown>
    </MenuWrapper>
  )
}
