import styled from 'styled-components'

export const SelectCurrencyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2rem;
`

export const SelectCurrencyLabel = styled.div`
  color: #454a54;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4rem;
  margin-bottom: 0.5rem;

  .required {
    color: #ff4c4d;
  }
`

export const SelectCurrencyStyled = styled.div`
  width: 100%;
  position: relative;
`

export const CurrencyButtonStyled = styled.div`
  background: #ffffff;
  border: 1px solid #e1e6ea;
  border-radius: 10px;

  width: 100%;
  height: 4.4rem;
  padding-left: 1rem;
  padding-right: 18px;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  color: #a3b1bf;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;

  &.error {
    border-color: #ff3131;
  }
  &.static {
    cursor: default;
  }
  &.is-selected {
    background-color: rgba(22, 177, 18, 0.08);
  }

  .currency-info {
    display: flex;
    align-items: center;

    font-size: 1.4rem;

    .icon {
      width: 26px;
      height: 26px;
      margin-right: 1rem;
    }
  }

  .no-currency {
    color: #a3b1bf;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    padding: 0 1.6rem;
  }
`

export const CurrencyListStyled = styled.div<{ opened: boolean }>`
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  z-index: 101;
  max-height: 25rem;

  background-color: #ffffff;
  border-radius: 11px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
  padding: 1rem 0;

  overflow-y: auto;

  color: #808080;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  .currency-name {
    color: #000 !important;
  }
`

export const CurrencyStyled = styled.div`
  width: 100%;
  height: 4rem;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 1rem;

  :hover {
    background: rgba(220, 223, 227, 0.4);
  }

  .icon {
    width: 26px;
    height: 26px;
    margin-right: 1rem;

    .icon-all {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      background: #85a0ad;
      border-radius: 4px;
      color: #ffffff;
      font-family: inherit;
      font-size: 1rem;
      font-style: normal;
      line-height: 1.2rem;
    }
  }

  .content {
    display: flex;
    flex-flow: column nowrap;

    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;

    .balance {
      color: #000000;
      font-weight: 500;
    }
  }
`

export const SelectCurrencyError = styled.div`
  color: #ff3131;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;

  margin-top: 5px;
`
