import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { enableMapSet, enableES5 } from 'immer'

import './i18n'

import 'react-phone-input-2/lib/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'

import App from './App'
import { store } from './store'

enableMapSet()
enableES5()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('banky-app')
)
