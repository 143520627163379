import styled from 'styled-components'

import { CommonSignupContainer, CommonSignupContent, CommonSignupForm, CommonSignupWrapper } from '../../../components'

export const LoginWrapper = styled(CommonSignupWrapper)``

export const LoginContainer = styled(CommonSignupContainer)``

export const LoginContent = styled(CommonSignupContent)``

export const LoginForm = styled(CommonSignupForm)``

export const LoginLink = styled.div`
  margin-top: -1rem;
  margin-bottom: 3rem;
  text-align: right;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;

  a {
    text-decoration: none;
    outline: none;
  }
`

export const LoginSignup = styled.div`
  color: #454a54;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;

  margin-top: 3rem;
  text-align: center;

  a {
    color: #454a54;
    font-weight: 700;
    text-decoration: none;
    outline: none;
  }
`
