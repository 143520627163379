import { useContext, useState } from 'react'
import toast from 'react-hot-toast'
import { v4 as uuid } from 'uuid'

import { OtpContext, tr, useFormValues } from 'mmfintech-commons'
import { Button, ErrorDisplay, LabelValue } from 'mmfintech-portal-commons'
import { usePayoutPreviewMutation, usePayoutInitiateMutation } from 'mmfintech-backend-api'

import { InputAmount } from '../../../../components'
import { PayoutPreview } from './PayoutPreview'
import { PayoutModalWrapper } from './PayoutModal.styled'

import { TransactionViewPortal } from 'mmfintech-commons-types'

type PayoutModalProps = {
  transaction: TransactionViewPortal
  reload: () => void
}

export const PayoutModal = ({ transaction, reload }: PayoutModalProps) => {
  const { setOtpOnSuccess } = useContext(OtpContext)

  const { id, checkoutDetails, currency, paymentMethod } = transaction || {}
  const { customerName, customerEmail } = checkoutDetails || {}

  // const paymentOption: PaymentOption = {
  //   status: 'AVAILABLE',
  //   minAmount: 0,
  //   maxAmount: amount
  // }

  const [payoutPreview, { error: payoutPreviewError, isLoading: payoutPreviewFetching, data: payoutPreviewData }] =
    usePayoutPreviewMutation({
      fixedCacheKey: 'payout-preview'
    })
  const [payoutInit, { error: payoutInitError, isLoading: payoutInitFetching }] = usePayoutInitiateMutation({
    fixedCacheKey: 'payout-init'
  })

  const isFormDataValid = () => {
    // const test = checkAmountLimits(fixFloat(formValues.getValue('amount')), paymentOption)
    // if (!test.valid) {
    //   formValues.setError('amount', translateError(test), test)
    //   return false
    // }
    return true
  }
  const [step, setStep] = useState(1)

  const formValues = useFormValues({
    amount: { required: true, validation: 'float', value: transaction?.amount.toString() }
  })

  const handlePreview = async (payload: any) => {
    try {
      await payoutPreview(payload).unwrap()
      setStep(2)
    } catch (_err) {}
  }

  const handleRefundInit = async (payload: any) => {
    setOtpOnSuccess(() => () => {
      toast.success(tr('FRONTEND.TRANSACTIONS.PAYOUT_SUCCESS', 'Payout initiated'))
      reload()
    })
    try {
      await payoutInit(payload).unwrap()
    } catch (_err) {}
  }

  const handleSubmit = () => {
    if (isFormDataValid()) {
      const payload = {
        payoutForeignTransactionId: uuid(),
        originalTransactionId: transaction.id,
        amount: formValues.getValue('amount')
      }

      switch (step) {
        case 1:
          void handlePreview(payload)
          break

        case 2:
          void handleRefundInit(payload)
          break

        default:
        // nothing
      }
    }
  }

  return (
    <PayoutModalWrapper>
      {step === 1 ? (
        <>
          <h3>{tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.TITLE', 'Payout payment')}</h3>

          <div className='info-container'>
            <LabelValue
              label={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.TRANSACTION_ID', 'Transaction ID')}
              value={id}
              colon
              dataTest='transaction-id'
            />
            <LabelValue
              label={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.PAYMENT_METHOD', 'Payment method')}
              value={tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
              colon
              dataTest='payment-method'
            />
            <LabelValue
              label={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.CUSTOMER_NAME', 'Customer name')}
              value={customerName}
              colon
              dataTest='customer-name'
            />
            <LabelValue
              label={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.CUSTOMER_EMAIL', 'Customer email')}
              value={customerEmail}
              colon
              dataTest='customer-email'
            />
          </div>

          <div className='amount-container'>
            <InputAmount
              account={{ currencyCode: currency }}
              error={formValues.getError('amount')}
              amount={formValues.getValue('amount')}
              setAmount={value => formValues.setValue('amount', value)}
              //paymentOption={paymentOption}
              autoFocus
            />
            <div>{currency}</div>
          </div>
        </>
      ) : null}

      {step === 2 ? <PayoutPreview previewData={payoutPreviewData} checkoutDetails={checkoutDetails} /> : null}

      <ErrorDisplay error={[payoutInitError, payoutPreviewError]} />

      <Button
        color='primary'
        text={
          step === 1
            ? tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')
            : tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.PAYOUT_BUTTON', 'Payout')
        }
        onClick={handleSubmit}
        disabled={formValues.getFloat('amount') <= 0}
        loading={payoutInitFetching || payoutPreviewFetching}
      />
    </PayoutModalWrapper>
  )
}
