import { useState, useEffect, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import cn from 'classnames'

import Select, { components } from 'react-select'
import { SelectInputWrapper } from './SelectInput.styled'

import { tr } from 'mmfintech-commons'
import { selectCurrentLanguage, useAppSelector } from 'mmfintech-backend-api'

type SelectInputProps = {
  id?: string
  name?: string
  error?: string
  label?: string
  value?: string | number
  options?: any[]
  onChange: (name: string, value: string) => void
  disabled?: boolean
  className?: string
  applyStyles?: any
  placeholder?: string
  disableSearch?: boolean
  hideLabel?: boolean
  hideRequired?: boolean
  hideErrorLine?: boolean
  noOptionsMessage?: string
  required?: boolean
  dataTest?: string
}

export const SelectInput = (props: SelectInputProps) => {
  // noinspection JSCheckFunctionSignatures
  const themeContext = useContext(ThemeContext)

  const {
    id,
    name,
    error,
    label,
    value,
    options,
    onChange,
    dataTest,
    disabled,
    required = false,
    className,
    hideLabel,
    applyStyles,
    placeholder,
    hideRequired,
    disableSearch,
    hideErrorLine,
    noOptionsMessage
  } = props
  const [selectedOption, setSelectedOption] = useState(null)
  const [translatedOptions, setTranslatedOptions] = useState([])

  const getPlaceholder = () => {
    if (placeholder?.length) return placeholder
    return (required && !hideRequired ? '* ' : '') + label
  }

  const handleChange = selectedOption => {
    onChange && onChange(name, selectedOption.value)
    setSelectedOption(selectedOption)
  }

  const isLabelVisible = () => {
    if (label?.length) {
      return value != null && value.toString().length > 0
    }
    return false
  }

  const selectedLanguage = useAppSelector(selectCurrentLanguage)

  useEffect(() => {
    const filteredOptions = options?.map(option => ({
      ...option,
      label: option?.localizationKey ? tr(option?.localizationKey) : option.label
    }))

    setTranslatedOptions(filteredOptions)
  }, [options, selectedLanguage])

  const defaultStyles = {
    menu: provided => ({
      ...provided,
      fontSize: '1.8rem',
      border: 'none !important',
      backgroundColor: '#ffffff !important'
    }),
    control: provided => ({
      ...provided,
      height: '5rem',
      borderRadius: '3px',
      borderColor: error && error.length > 0 ? themeContext.errorDisplay.borderColor : '#a3b8c2',
      borderBottomColor: error && error.length > 0 ? themeContext.errorDisplay.borderBottomColor : '#a3b8c2',
      boxShadow: 'none',
      backgroundColor: '#ffffff'
    }),
    placeholder: provided => ({
      ...provided,
      color: '#a3b8c2',
      fontSize: '1.8rem',
      fontStyle: 'normal',
      fontWeight: 'normal'
    }),
    valueContainer: provided => ({
      ...provided,
      color: '#000000',
      fontSize: '1.8rem',
      fontWeight: '500',
      padding: '2px 0'
    }),
    singleValue: provided => ({
      ...provided,
      color: '#000000'
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: isFocused ? '#ffffff' : '#000000',
      backgroundColor: isFocused ? '#000000' : 'transparent'
    })
  }

  useEffect(() => {
    const option = translatedOptions?.find(item => item.value === value || item.value === value?.toString())
    setSelectedOption(option ?? null)
    // eslint-disable-next-line
  }, [value, translatedOptions, selectedOption])

  const addDataTest = (Component, dataTest) => props =>
    <Component {...props} innerProps={Object.assign({}, props.innerProps, { 'data-test': dataTest })} />

  return (
    <SelectInputWrapper className='select-wrapper'>
      {!hideLabel ? (
        <label>
          {isLabelVisible() && required && !hideRequired && <span className='asterisk'>*</span>}
          {isLabelVisible() && <span>{label}</span>}
          {!isLabelVisible() && ' '}
        </label>
      ) : null}

      <Select
        id={id || name}
        name={name}
        isDisabled={disabled}
        isSearchable={!disableSearch}
        placeholder={getPlaceholder()}
        className={cn('select-input', className, { error: error && error.length > 0 })}
        options={translatedOptions}
        value={selectedOption}
        styles={applyStyles || defaultStyles}
        onChange={handleChange}
        noOptionsMessage={() => noOptionsMessage || 'No options'}
        required={required}
        components={{
          IndicatorSeparator: () => null,
          ...(dataTest?.length ? { SelectContainer: addDataTest(components.SelectContainer, dataTest) } : null)
        }}
        menuPlacement={'bottom'}
        menuPosition={'absolute'}
      />

      {!hideErrorLine || error ? <span className='error-message'>{error}</span> : null}
    </SelectInputWrapper>
  )
}
