import styled from 'styled-components'

export const ChartWrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid #e4e6e9;

  padding: 1.2rem;

  width: 100%;
  height: 100%;
  max-height: 34rem;

  canvas {
    width: 100%;
    max-width: 100%;
  }

  &.loading {
    border-color: transparent;
  }
`
