import { useContext, useState } from 'react'
import toast from 'react-hot-toast'
import { v4 as uuid } from 'uuid'

import { InputAmount } from '../../../../components'
import { RefundPreview } from './RefundPreview'
import { RefundModalWrapper } from './RefundModal.styled'
import { Button, ErrorDisplay, LabelValue } from 'mmfintech-portal-commons'
import { useRefundPreviewMutation, useRefundInitiateMutation } from 'mmfintech-backend-api'

import { checkAmountLimits, fixFloat, OtpContext, tr, translateError, useFormValues } from 'mmfintech-commons'

import { PaymentOption, TransactionViewPortal } from 'mmfintech-commons-types'

export const RefundModal = ({ transaction, reload }: { transaction: TransactionViewPortal; reload: () => void }) => {
  const { setOtpOnSuccess } = useContext(OtpContext)

  const { amount, id, checkoutDetails, currency, paymentMethod } = transaction || {}
  const { customerName, customerEmail } = checkoutDetails || {}

  const paymentOption: PaymentOption = {
    status: 'AVAILABLE',
    minAmount: 0,
    maxAmount: amount
  }

  const [refundInit, { error: refundInitError, isLoading: refundInitFetching }] = useRefundInitiateMutation({
    fixedCacheKey: 'refund-init'
  })
  const [refundPreview, { error: refundPreviewError, isLoading: refundPreviewFetching, data: refundPreviewData }] =
    useRefundPreviewMutation({
      fixedCacheKey: 'refund-preview'
    })

  const isFormDataValid = () => {
    const test = checkAmountLimits(fixFloat(formValues.getValue('amount')), paymentOption)
    if (!test.valid) {
      formValues.setError('amount', translateError(test), test)
      return false
    }

    return true
  }
  const [step, setStep] = useState(1)

  const formValues = useFormValues({
    amount: { required: true, validation: 'float', value: transaction?.amount.toString() }
  })

  const handlePreview = async (payload: any) => {
    try {
      await refundPreview(payload).unwrap()
      setStep(2)
    } catch (_err) {}
  }

  const handleRefundInit = async (payload: any) => {
    setOtpOnSuccess(() => () => {
      toast.success(tr('FRONTEND.TRANSACTIONS.REFUND_SUCCESS', 'Refund initiated'))
      reload()
    })
    try {
      await refundInit(payload).unwrap()
    } catch (_err) {}
  }

  const handleSubmit = () => {
    if (isFormDataValid()) {
      const payload = {
        refundForeignTransactionId: uuid(),
        originalTransactionId: transaction.id,
        amount: formValues.getValue('amount')
      }

      switch (step) {
        case 1:
          void handlePreview(payload)
          break

        case 2:
          void handleRefundInit(payload)
          break

        default:
          break
      }
    }
  }

  return (
    <RefundModalWrapper>
      {step === 1 ? (
        <>
          <h3>{tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.TITLE', 'Refund payment')}</h3>

          <div className='info-container'>
            <LabelValue
              label={tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.TRANSACTION_ID', 'Transaction ID')}
              value={id}
              colon
              dataTest='transaction-id'
            />
            <LabelValue
              label={tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.PAYMENT_METHOD', 'Payment method')}
              value={tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
              colon
              dataTest='payment-method'
            />
            <LabelValue
              label={tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.CUSTOMER_NAME', 'Customer name')}
              value={customerName}
              colon
              dataTest='customer-name'
            />
            <LabelValue
              label={tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.CUSTOMER_EMAIL', 'Customer email')}
              value={customerEmail}
              colon
              dataTest='customer-email'
            />
          </div>

          <div className='amount-container'>
            <InputAmount
              account={{ currencyCode: currency }}
              error={formValues.getError('amount')}
              amount={formValues.getValue('amount')}
              setAmount={value => formValues.setValue('amount', value)}
              paymentOption={paymentOption}
              autoFocus
            />
            <div>{currency}</div>
          </div>
        </>
      ) : null}

      {step === 2 ? <RefundPreview previewData={refundPreviewData} checkoutDetails={checkoutDetails} /> : null}

      <ErrorDisplay error={[refundInitError, refundPreviewError]} />

      <Button
        color='primary'
        text={
          step === 1
            ? tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')
            : tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.REFUND_BUTTON', 'Refund')
        }
        onClick={handleSubmit}
        disabled={formValues.getFloat('amount') <= 0}
        loading={refundInitFetching || refundPreviewFetching}
      />
    </RefundModalWrapper>
  )
}
