import styled from 'styled-components'

export const ConfirmationWrapper = styled.div`
  position: relative;
`

export const ConfirmationContainer = styled.div`
  width: 100vw;
  max-width: 50rem;
  padding: 2rem;

  @media (min-width: 480px) {
    padding: 3rem 5rem 4rem;
  }

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;

  color: #454a54;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.9rem;
`

export const ConfirmationContent = styled.div`
  width: 100%;
  margin-bottom: 5rem;
`

export const ConfirmationTitle = styled.div`
  color: #454a54;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.8rem;

  margin-bottom: 1rem;
`
