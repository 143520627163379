import styled from 'styled-components'

export const OtpWrapper = styled.div`
  padding: 2rem;
  @media (min-width: 360px) {
    padding: 3rem 5rem 3.5rem;
  }

  width: 100%;
  max-width: 50rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  color: #454a54;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.9rem;

  .err-msg-wrapper {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    color: #ff4c4d;
    font-family: inherit;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4rem;
  }
`

export const OtpTitle = styled.div`
  color: #454a54;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.8rem;

  margin-bottom: 2rem;
`

export const OtpSubtitle = styled.div`
  margin-bottom: 4rem;

  span {
    display: block;
    font-weight: bold;
    overflow-wrap: anywhere;
  }
`

export const OtpInput = styled.input`
  color: #454a54;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.8rem;
  letter-spacing: 1rem;

  text-align: center;

  width: 100%;
  padding: 2rem 0;

  border: 1px solid #e1e6ea;
  border-radius: 1rem;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: #b4bfcb;
    font-family: inherit;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.8rem;
    letter-spacing: normal;
  }
  &:-ms-input-placeholder {
    color: #b4bfcb;
    font-family: inherit;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.8rem;
    letter-spacing: normal;
  }
  &::placeholder {
    color: #b4bfcb;
    font-family: inherit;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.8rem;
    letter-spacing: normal;
  }

  &.wrong {
    border-color: #ff4c4d;
  }
`

export const OtpButtonsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: inherit;

  margin-top: 3rem;
  margin-bottom: 2rem;

  .button {
    margin-top: 2rem;
    &:first-child {
      margin-top: 0;
    }
  }
`

export const OtpFooter = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 2rem;

  color: #454a54;
  font-family: inherit;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;
`

export const OtpResendWrapper = styled.div`
  span {
    margin-top: 5px;
    display: block;
    text-decoration: underline;
    cursor: pointer;
    color: #1e63ff;

    &.disabled {
      color: #454a54;
      cursor: default;
      text-decoration: none;
    }
  }
`

export const OtpCountdown = styled.div`
  color: #b4bfcb;

  &.expired {
    color: #ff4c4d;
  }

  span {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`
