import styled, { css } from 'styled-components'

export const DropDownWrapper = styled.span`
  position: relative;

  .icon-container {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

export const DropDownButton = styled.div`
  background-color: rgba(180, 191, 203, 0.8);
  border-radius: 1rem;
  cursor: pointer;

  min-width: 11rem;
  height: 4.4rem;
  padding: 0 2rem;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  color: #ffffff;
  font-family: inherit;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;
  white-space: nowrap;

  &.opened {
    color: #ffffff;
  }

  .icon {
    margin-right: 8px;
  }
  .chevron {
    margin-left: 1.8rem;
  }
`

export const DropDownContent = styled.div<{ alternative: boolean }>`
  z-index: 1001;
  position: absolute;

  display: none;
  &.opened {
    display: inline-block;
  }

  .button {
    background: #ffce32 !important;
    width: auto;
    padding: 0 2rem;
  }

  .dropdown-container {
    border-radius: 5px;
    border: 1px solid #e1e5ea;
    background: #fff;
    display: flex;
    flex-flow: column nowrap;

    width: 16rem;
    padding: 1rem 2rem;

    & > * {
      border-top: 1px solid #e1e5ea;
      &:first-of-type {
        border-top: none;
      }

      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      padding: 0 1rem;

      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1rem;
    }
  }

  ${({ alternative }) => {
    if (alternative) {
      return css`
        top: 3rem;
        @media (max-width: 767px) {
          left: 0;
        }
        @media (min-width: 768px) {
          right: 5%;
        }
      `
    }
    return css`
      top: 5.4rem;
      right: 0;
    `
  }}
`
