import styled from 'styled-components'

export const IbanRequestModalWrapper = styled.div`
  width: 100vw;
  max-width: 92rem;
  background: #ffffff;
  margin: 0 1rem;
  padding: 3rem 2rem;
  overflow: auto;

  @media (min-width: 940px) {
    padding: 4rem 5rem;
  }

  .fees-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;

    & > * {
      flex: 1;
    }
    @media (min-width: 940px) {
      flex-direction: row;
    }
  }
`

export const IbanRequestHeading1 = styled.div`
  display: block;
  text-align: center;
  margin-bottom: 5rem;

  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.9rem;
`

export const IbanRequestHeading2 = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.9rem;
`

export const IbanRequestHeading3 = styled.div`
  color: #85a0ad;
  font-size: 1.2rem;
  line-height: 1.5rem;
`

export const IbanRequestTabs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
`

export const IbanRequestTab = styled.div`
  border-bottom: 3px solid rgba(163, 184, 194, 0.5);
  cursor: pointer;

  color: #85a0ad;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
  padding: 2rem;
  text-align: center;
  width: 100%;

  &.active {
    color: #000000;
    border-bottom: 3px solid #000000;
  }
`

export const IbanRequestBadges = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
  margin: 2.5rem 0 4.5rem 0;
`

export const IbanRequestAccounts = styled.div`
  margin: 3.5rem 0;

  .inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    max-height: 25rem;
    overflow: auto;
    padding: 1rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`

export const IbanRequestFees = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1.8rem;
`

export const IbanRequestFeeTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 25rem;
  width: 100%;

  color: #1a1a1a;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;

  &:last-of-type {
    padding-bottom: 2rem;
    border-bottom: 1px solid #e1e5ea;
  }

  .fee {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.9rem;
  }
`

export const IbanRequestButtons = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-flow: column-reverse;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-flow: row;
  }

  button {
    width: 100%;
  }
`
