export const WithdrawIcon = ({ color = '#1e63ff' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='22' viewBox='0 0 16 22' fill='none'>
    <path
      d='M11.5 7.5H13.5C14.3284 7.5 15 8.17157 15 9V17C15 17.8284 14.3284 18.5 13.5 18.5H2.5C1.67157 18.5 1 17.8284 1 17V9C1 8.17157 1.67157 7.5 2.5 7.5H4.5'
      stroke={color}
      strokeLinecap='round'
    />
    <path d='M12.0625 11.0796L8 15.1421L3.9375 11.0796' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8 15.1421L8 3.76709' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
