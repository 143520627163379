import { useState } from 'react'

import LimitsTable from './LimitsTable'
import RequestsTable from './RequestsTable'
import { RequestsWrapper, TableWrapper, TabsWrapper, Tab, RequestsTitle } from './styles/Requests.styled'

import { tr } from 'mmfintech-commons'
import { selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'
import { CustomerRoleEnum } from 'mmfintech-commons-types'

type Tabs = 'requests' | 'limits'

const Requests = () => {
  const customerRole = useAppSelector(selectCurrentUserRole)

  const [activeTab, setActiveTab] = useState<Tabs>('requests')

  const handleTabClick = (tab: Tabs): void => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <RequestsWrapper>
      {customerRole === CustomerRoleEnum.STAFF ? (
        <RequestsTitle>{tr('FRONTEND.EXPENSE_MANAGEMENT.EXPENSE_REQUESTS', 'Expense requests')}</RequestsTitle>
      ) : (
        <div className='expenses-header'>
          <RequestsTitle>{tr('FRONTEND.EXPENSE_MANAGEMENT.REQUESTS_TITLE', 'Requests')}</RequestsTitle>

          <TabsWrapper>
            <Tab active={activeTab === 'requests'} onClick={() => handleTabClick('requests')} data-test='requests-tab'>
              {tr('FRONTEND.EXPENSE_MANAGEMENT.TAB_EXPENSE_REQUESTS', 'Expense requests')}
            </Tab>
            <Tab active={activeTab === 'limits'} onClick={() => handleTabClick('limits')} data-test='limits-tab'>
              {tr('FRONTEND.EXPENSE_MANAGEMENT.TAB_LIMITS', 'Limits')}
            </Tab>
          </TabsWrapper>
        </div>
      )}
      <TableWrapper>
        {activeTab === 'requests' || customerRole === CustomerRoleEnum.STAFF ? <RequestsTable /> : null}
        {activeTab === 'limits' ? <LimitsTable /> : null}
      </TableWrapper>
    </RequestsWrapper>
  )
}

export default Requests
