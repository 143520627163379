import styled from 'styled-components'

export const CookieConsentWrapper = styled.div`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  flex-flow: column nowrap;

  z-index: 20;
  width: 95vw;
  max-width: 50rem;

  position: fixed;
  right: 1rem;
  bottom: ${({ isHidden }) => (isHidden ? '-100%' : '2rem')};

  background: linear-gradient(to top right, #0000 50%, #ffffff 50.1%) top right/10rem 10rem no-repeat, #1e63ff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  color: #ffffff;
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;

  @media (min-width: 600px) {
    right: 5rem;
    bottom: ${({ isHidden }) => (isHidden ? '-100%' : '4rem')};
  }
`

export const ConsentContent = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 50px;
  padding: 3rem 3rem 2.5rem;
  border-bottom: 1px solid #5587f6;

  .title {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.8rem;
    margin-bottom: 1rem;
  }
`

export const ConsentFooter = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  padding: 2rem 3rem;

  button {
    height: 5rem;
    width: 100%;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.9rem;

    border-radius: 1rem;
    box-shadow: none;
    outline: none;

    &.manage {
      color: #ffffff;
      background: #1e63ff;
      border: 1px solid #ffffff;
    }
    &.consent {
      color: #000000;
      background: #ffffff;
    }
  }

  @media (min-width: 500px) {
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
  }
`
