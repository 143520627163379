import { InputAmountLimitsStyled } from './InputAmountLimits.styled'

import { useCurrencies } from 'mmfintech-backend-api'
import { formatMoney, tr } from 'mmfintech-commons'

import { PaymentOptionStatusEnum } from 'mmfintech-commons-types'

interface InputAmountLimitsProps {
  currency?: string
  paymentOption?: any
}

export const InputAmountLimits = ({ currency, paymentOption }: InputAmountLimitsProps) => {
  const { getCurrencyPrecision } = useCurrencies()
  const decimals = getCurrencyPrecision(currency)

  const getLimits = () => {
    const { available, status, minAmount, maxAmount } = paymentOption || {}

    if (available || status === PaymentOptionStatusEnum.AVAILABLE) {
      const res = []

      if (minAmount > 0) {
        res.push(tr('FRONTEND.MONEY_INPUT.LABEL_MIN', 'Min') + ': ' + formatMoney(minAmount, currency, decimals))
      }
      if (maxAmount > 0) {
        const formattedAmount = formatMoney(maxAmount, currency, decimals)
        if (maxAmount >= minAmount) {
          res.push(`${tr('FRONTEND.MONEY_INPUT.LABEL_MAX', 'Max')}: ${formattedAmount}`)
        } else {
          res.push(
            `${tr('FRONTEND.MONEY_INPUT.LABEL_MAX_AVAILABLE', 'Max available including fees')}: ${formattedAmount}`
          )
        }
      }

      return res.length > 0 && res.join(', ')
    }

    return null
  }

  return <InputAmountLimitsStyled>{getLimits() || ' '}</InputAmountLimitsStyled>
}
