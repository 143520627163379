export const PaymentLinkIcon = ({ color = '#1e63ff' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'>
    <path
      d='M4.39307 1.14307H1.17878C0.882914 1.14307 0.643066 1.38291 0.643066 1.67878V4.89307C0.643066 5.18893 0.882914 5.42878 1.17878 5.42878H4.39307C4.68893 5.42878 4.92878 5.18893 4.92878 4.89307V1.67878C4.92878 1.38291 4.68893 1.14307 4.39307 1.14307Z'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.39307 7.57129H1.17878C0.882914 7.57129 0.643066 7.81114 0.643066 8.107V11.3213C0.643066 11.6172 0.882914 11.857 1.17878 11.857H4.39307C4.68893 11.857 4.92878 11.6172 4.92878 11.3213V8.107C4.92878 7.81114 4.68893 7.57129 4.39307 7.57129Z'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.8213 1.14307H7.607C7.31114 1.14307 7.07129 1.38291 7.07129 1.67878V4.89307C7.07129 5.18893 7.31114 5.42878 7.607 5.42878H10.8213C11.1172 5.42878 11.357 5.18893 11.357 4.89307V1.67878C11.357 1.38291 11.1172 1.14307 10.8213 1.14307Z'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7.07129 7.57129V9.71415' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7.07129 11.857H9.21415V7.57129' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.21436 8.64307H11.3572' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.3569 10.7856V11.8571' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
