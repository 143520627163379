import React, { ReactNode } from 'react'

import { InputAmountLimits } from './InputAmountLimits'
import {
  InputAmountElement,
  InputAmountError,
  InputAmountLabel,
  InputAmountStatic,
  InputAmountWrapper
} from './InputAmount.styled'

import { useCurrencies } from 'mmfintech-backend-api'
import { checkSingleValue, isValidFunction, isValidString } from 'mmfintech-commons'

import { AccountBalanceOut, PaymentOptionResponse } from 'mmfintech-commons-types'

type InputAmountProps = {
  label?: ReactNode
  amount?: string
  account?: AccountBalanceOut
  setAmount?: (value: string) => void
  paymentOption?: PaymentOptionResponse
  error?: string
  setAmountError?: (error: string) => void
  placeholder?: string
  dataTest?: string
  autoFocus?: boolean
  required?: boolean
  bordered?: boolean
  [key: string]: any
}

export const InputAmount = ({
  label,
  amount,
  account,
  setAmount,
  paymentOption,
  error,
  setAmountError,
  placeholder,
  dataTest,
  autoFocus,
  ...rest
}: InputAmountProps) => {
  const { currencyCode } = account || {}

  const { getCurrencyPrecision } = useCurrencies()

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidFunction(setAmount)) {
      const val = checkSingleValue(e.target.value, {
        validation: 'float',
        decimals: getCurrencyPrecision(currencyCode)
      })
      setAmount(val)
      setAmountError && setAmountError(null)
    }
  }

  return (
    <InputAmountWrapper {...rest}>
      <InputAmountLabel>{label}</InputAmountLabel>

      {isValidFunction(setAmount) ? (
        <InputAmountElement
          type='text'
          value={amount || ''}
          onChange={handleAmountChange}
          placeholder={placeholder || '0'}
          maxLength={16}
          data-test={dataTest}
          autoFocus={autoFocus}
        />
      ) : (
        <InputAmountStatic data-test={dataTest}>{amount || 0}</InputAmountStatic>
      )}

      {isValidString(error) && <InputAmountError>{error}</InputAmountError>}
      <InputAmountLimits currency={currencyCode} paymentOption={paymentOption} />
    </InputAmountWrapper>
  )
}
