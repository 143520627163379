import cn from 'classnames'

import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import {
  OtpButtonsContainer,
  OtpCountdown,
  OtpFooter,
  OtpInput,
  OtpResendWrapper,
  OtpSubtitle,
  OtpTitle,
  OtpWrapper
} from './Otp.styled'

import { globalSettings, useAuth, useOtpQry } from 'mmfintech-backend-api'
import { formatPhoneNumber, isValidFunction, tr, useValidUntilTimer } from 'mmfintech-commons'

import { LoginStatusEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'

type OtpProps = {
  onCancel?: () => void
}

export const Otp = ({ onCancel }: OtpProps) => {
  const {
    code,
    codeSize,
    attempts,
    challenge,
    handleCodeChanged,
    handleCancel,
    handleSubmit,
    handleResend,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpFetching,
    resendTimer
  } = useOtpQry()

  const userStatus = useAuth()
  const timer = useValidUntilTimer()

  const { sentTo, twoFactorType } = challenge || {}

  const internalCancel = () => {
    isValidFunction(onCancel) && onCancel()
    handleCancel()
  }

  const prepareTitle = () => {
    if (twoFactorType === TwoFactorTypeEnum.SMS) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS verification')
      }

      return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS confirmation')
    }

    if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'Email verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'Email confirmation')
  }

  return (
    <OtpWrapper data-test='otp-verification-dialog'>
      <OtpTitle className='title'>{prepareTitle()}</OtpTitle>
      <OtpSubtitle>
        {tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', "Please, enter the verification code that we've sent to:")}
        <span>{twoFactorType === TwoFactorTypeEnum.SMS ? formatPhoneNumber(sentTo) : sentTo}</span>
      </OtpSubtitle>

      <OtpInput
        id='otp'
        type='tel'
        className={cn({
          complete: code?.length === codeSize,
          wrong: code?.length === codeSize && verifyOtpError != null
        })}
        autoComplete='off'
        onChange={e => handleCodeChanged(e.target.value)}
        value={code}
        maxLength={codeSize}
        autoFocus
        placeholder={tr('FRONTEND.VERIFY_OTP.ENTER_CODE', 'Enter code')}
        disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
        data-test='tel-input'
        onKeyUp={event => {
          if (event.key === 'Enter') {
            void handleSubmit()
          }
        }}
      />

      <ErrorDisplay error={verifyOtpError} />

      <OtpButtonsContainer>
        <Button
          type='submit'
          color='primary'
          text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
          loading={resendOtpFetching || verifyOtpFetching}
          onClick={handleSubmit}
          disabled={code.length !== codeSize || attempts >= globalSettings.otpMaxAttempts || timer.expired}
          data-test='button-submit'
        />

        {onCancel && (
          <Button
            type='button'
            color='secondary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            disabled={resendOtpFetching || verifyOtpFetching}
            onClick={internalCancel}
            data-test='button-cancel'
          />
        )}
      </OtpButtonsContainer>

      <OtpFooter>
        <OtpResendWrapper>
          {attempts >= globalSettings.otpMaxAttempts ? (
            tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
          ) : (
            <>
              {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it?")}
              <span className={cn({ disabled: !resendTimer.expired })} onClick={handleResend}>
                {resendTimer.expired
                  ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send code')
                  : resendTimer.remainingTime}
              </span>
            </>
          )}
        </OtpResendWrapper>

        {attempts >= globalSettings.otpMaxAttempts || resendOtpFetching || verifyOtpFetching ? null : (
          <OtpCountdown>{timer.formattedTime}</OtpCountdown>
        )}
      </OtpFooter>
    </OtpWrapper>
  )
}
