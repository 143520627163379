import { Preloader } from 'mmfintech-portal-commons'
import { AccountsOverviewRow } from './AccountsOverviewRow'
import { AccountsOverviewStyled } from './AccountsOverview.styled'

import { useAccountPermissions, useGetActivePaymentAccountsQuery } from 'mmfintech-backend-api'

export const AccountsOverview = () => {
  const { fetching, permissions } = useAccountPermissions()
  const { data: activePaymentAccounts, isFetching: activePaymentAccountsFetching } = useGetActivePaymentAccountsQuery()

  if (activePaymentAccountsFetching || fetching) {
    return <Preloader />
  }

  const accounts = activePaymentAccounts?.map((a: any) => {
    const permission = permissions?.find((perm: any) => a?.id === perm?.accountId)
    return { ...a, permission: permission?.accessLevel }
  })

  return (
    <AccountsOverviewStyled>
      {Array.isArray(accounts) &&
        accounts.map((account: any, index: number) => <AccountsOverviewRow account={account} key={index} />)}
    </AccountsOverviewStyled>
  )
}
