import styled from 'styled-components'

export const SelectBankWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-bottom: 3rem;

  font-size: 1.6rem;

  @media (max-width: 500px) {
    padding: 2rem 1rem;
  }
`

export const BankRadioWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  border: 2px solid transparent;

  &.selected {
    border-color: #000000;
    border-radius: 7px;
  }
`

export const BankRadioInner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  width: 100%;
  min-height: 7.5rem;
  padding: 0 2rem;

  color: #000000;
  border-radius: 7px;
  background-color: #f5f5f5;

  img {
    margin-left: 2rem;
  }

  @media (max-width: 500px) {
    flex-flow: column-reverse nowrap;
    align-items: center;
    justify-content: flex-end;
    padding: 2rem 3rem;
    text-align: center;

    img {
      margin-left: 0;
      margin-bottom: 1rem;
    }
  }
`
