import styled from 'styled-components'
import { BasePageTitle, BasePageWrapper } from '../../../components'

type TabProps = {
  active: boolean
}

export const RequestsTitle = styled(BasePageTitle)`
  margin-bottom: 0;
  text-align: left;
`

export const RequestsWrapper = styled(BasePageWrapper)`
  .expenses-header {
    display: flex;
    flex-flow: column nowrap;
  }

  .headRow {
    th:hover {
      cursor: pointer;
    }

    .activeSortCell {
      color: black !important;
    }

    th:last-of-type {
      text-align: center;
    }

    .sort-icons {
      margin-right: 0.5rem;
    }
  }
`
export const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;

  flex: 1;

  margin-bottom: 3rem;
`
export const Tab = styled.div<TabProps>`
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;

  color: #85a0ad;
  border-bottom: 2px solid #ccd7dc;
  cursor: pointer;
  padding: 2rem;

  ${({ active }) =>
    active &&
    `
    color: #000000;
    border-bottom: 2px solid #000000;;
  `}
  &:last-of-type {
    padding: 2rem 4rem;
  }
`
export const TableWrapper = styled.div``
