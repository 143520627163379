import { paths } from 'mmfintech-backend-api'
import { LoginStatusEnum } from 'mmfintech-commons-types'

export const statusPaths = status => {
  switch (status) {
    case LoginStatusEnum.NOT_VERIFIED:
      return paths.checkEmail()
    case LoginStatusEnum.FORGOT_PASSWORD:
      return paths.changePasswordEmail()
    case LoginStatusEnum.RESET_PASSWORD:
      return paths.handlePasswordResetInvited()
    case LoginStatusEnum.PASSWORD_RESET:
      return paths.passwordReset()
    default:
      return paths.login()
  }
}
