import { useContext } from 'react'

import { GlobalContext, tr } from 'mmfintech-commons'
import {
  usePayoutInitiateMutation,
  usePayoutPreviewMutation,
  useRefundInitiateMutation,
  useRefundPreviewMutation
} from 'mmfintech-backend-api'

import { PayoutModal } from '../PayoutModal'
import { RefundModal } from '../RefundModal/RefundModal'
import { CustomDropdown } from '../../../../components'

import DotsIcon from '../../../../images/icons/dots-icon.svg?react'
import DetailIcon from '../../../../images/icons/detail-icon.svg?react'
import PayoutIcon from '../../../../images/icons/payout-icon.svg?react'
import RefundIcon from '../../../../images/icons/refund-icon.svg?react'

export const TransactionDropdown = ({ transaction, reload, enablePayouts, enableRefunds, handleDetailsClick }) => {
  const { modalShow } = useContext(GlobalContext)

  const [_refundPreview, { reset: resetRefundPreview }] = useRefundPreviewMutation({
    fixedCacheKey: 'refund-preview'
  })
  const [_refundInit, { reset: resetRefundInitiation }] = useRefundInitiateMutation({
    fixedCacheKey: 'refund-init'
  })
  const [_payoutPreview, { reset: resetPayoutPreview }] = usePayoutPreviewMutation({
    fixedCacheKey: 'payout-preview'
  })
  const [_payoutInit, { reset: resetPayoutInitiation }] = usePayoutInitiateMutation({
    fixedCacheKey: 'payout-init'
  })

  const handlePayout = () => {
    modalShow({
      content: <PayoutModal transaction={transaction} reload={reload} />,
      options: {
        size: 'auto',
        onClose: () => {
          resetPayoutPreview()
          resetPayoutInitiation()
        }
      }
    })
  }

  const handleRefund = () => {
    modalShow({
      content: <RefundModal transaction={transaction} reload={reload} />,
      options: {
        size: 'auto',
        onClose: () => {
          resetRefundPreview()
          resetRefundInitiation()
        }
      }
    })
  }

  return (
    <CustomDropdown
      icon={<DotsIcon />}
      dropdown={
        <div className='dropdown-container'>
          <div onClick={() => handleDetailsClick(transaction?.id)}>
            <DetailIcon />
            <p>{tr('FRONTEND.TRANSACTIONS.VIEW_DETAILS_BUTTON', 'View details')}</p>
          </div>
          {enableRefunds ? (
            <div onClick={handleRefund}>
              <RefundIcon />
              <p>{tr('FRONTEND.TRANSACTIONS.REFUND_BUTTON', 'Refund')}</p>
            </div>
          ) : null}
          {enablePayouts ? (
            <div onClick={handlePayout}>
              <PayoutIcon />
              <p>{tr('FRONTEND.TRANSACTIONS.PAYOUT_BUTTON', 'Payout')}</p>
            </div>
          ) : null}
        </div>
      }
    />
  )
}
