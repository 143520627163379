import { ConfigureStoreOptions, configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'

import { middlewares, queryReducers } from 'mmfintech-backend-api'

const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: queryReducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares.apiMiddleware).concat(thunk),
    ...options
  })

export const store = createStore()

export type RootState = ReturnType<typeof store.getState>

type AppStore = ReturnType<typeof createStore>
export type AppDispatch = AppStore['dispatch']
