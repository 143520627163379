import styled from 'styled-components'

export const TableCustomizeWrapper = styled.div`
  width: 100vw;
  max-width: 45rem;

  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
  padding: 4rem 5rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  .button {
    width: 100%;
    max-width: 40rem;
    height: 5rem;
    border-radius: 1rem;

    font-size: 1.4rem;
    font-weight: 400;
    line-height: 120%;
    color: #ffffff;
  }
`

export const TableCustomizeTitle = styled.div`
  color: #454a54;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.8rem;
  margin-bottom: 2rem;
`
