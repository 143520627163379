import { tr } from 'mmfintech-commons'
import { toast, Toaster, ToastBar } from 'react-hot-toast'

import { ContentWrapper } from './CustomToaster.styled'

import CloseIcon from '../../images/icons/toaster-close.svg?react'

export const CustomToaster = () => (
  <Toaster
    gutter={10}
    position='top-right'
    containerStyle={{
      top: '10rem',
      zIndex: '10010'
    }}>
    {t => (
      <ToastBar
        toast={t}
        style={{
          display: 'block',
          backgroundColor: '#515151',
          borderRadius: '10px',
          color: '#ffffff',
          fontFamily: 'inherit',
          fontSize: '1.4rem',
          lineHeight: ' 1.7rem',
          fontStyle: 'normal',
          padding: '1.2rem',
          width: '100%',
          maxWidth: '320px'
        }}>
        {({ icon, message }) => (
          <ContentWrapper>
            <div className='icon'>{icon}</div>
            <div className='content'>
              <span className={`label`}>
                {t.type === 'success' ? tr('FRONTEND.COMMON.SUCCESS', 'Success') : tr('FRONTEND.COMMON.ERROR', 'Error')}
              </span>
              <span>{message}</span>
            </div>
            {t.type !== 'loading' && <CloseIcon className='close-icon' onClick={() => toast.dismiss(t.id)} />}
          </ContentWrapper>
        )}
      </ToastBar>
    )}
  </Toaster>
)
