import { useHistory } from 'react-router-dom'

import cn from 'classnames'

import { ChevronDownIcon, ChevronUpIcon } from '../../icons'
import { MenuContainer, MenuDropDown, MenuWrapper } from './MerchantSwitchMenu.styled'

import { tr, useDropDownMenu } from 'mmfintech-commons'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  api,
  paths,
  selectMerchant,
  useGetMerchantsForSwitchQuery,
  useSwitchMerchantMutation
} from 'mmfintech-backend-api'

export const MerchantSwitchMenu = ({ hamburger = false }) => {
  const { data: merchant } = useAppSelector(selectMerchant)
  const { data: merchants } = useGetMerchantsForSwitchQuery()

  const [switchMerchant, { isLoading: switchMerchantLoading }] = useSwitchMerchantMutation()

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const history = useHistory()
  const dispatch = useAppDispatch()

  const handleLanguageClick = async (merchantId: number) => {
    hideDropDown()
    if (!switchMerchantLoading && merchant?.merchantId !== merchantId) {
      try {
        await switchMerchant({ merchantId }).unwrap()
        history.push(paths.dashboard())
        dispatch(api.util.resetApiState())
      } catch (_error) {}
    }
  }

  const getCurrentMerchantName = () => {
    const find = Array.isArray(merchants) ? merchants.find(node => node.merchantId === merchant?.merchantId) : null
    return find ? find.name || '???' : tr('FRONTEND.HEADER.SWITCH_MERCHANT', 'Switch to')
  }

  if (Array.isArray(merchants) && merchants.length > 1) {
    const currentMerchantName = getCurrentMerchantName()

    return (
      <MenuWrapper data-test='merchant-menu-wrapper' className={cn({ hamburger })}>
        <MenuContainer className={cn({ opened: visible })} onClick={toggleDropDown}>
          <span className='icon-letter active'>{currentMerchantName ? currentMerchantName.substring(0, 1) : '?'}</span>
          <span className='name'>{currentMerchantName}</span>
          <span className='arrow'>{visible ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
        </MenuContainer>
        <MenuDropDown className={cn({ opened: visible })} data-test='language-menu-content'>
          <ul data-test='language-menu-list'>
            {Array.isArray(merchants) &&
              merchants.map(v => (
                <li key={v.merchantId} onClick={() => handleLanguageClick(v.merchantId)} data-test='merchant-menu-item'>
                  <span className={cn('icon-letter', { active: v.merchantId === merchant?.merchantId })}>
                    {v.name ? v.name.substring(0, 1) : '?'}
                  </span>
                  {v.name ? v.name : v.email}
                </li>
              ))}
          </ul>
        </MenuDropDown>
      </MenuWrapper>
    )
  }

  return null
}
