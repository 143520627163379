import styled from 'styled-components'

import { BasePageWrapper } from '../../components'

export const AccountsWrapper = styled(BasePageWrapper)``

export const AccountsContainer = styled.div`
  margin-bottom: 4rem;

  .data-table-cell,
  .data-table-head-cell {
    padding: 0 0.5rem;

    font-size: 1.4rem;
    font-weight: 400;
    line-height: 120%;
  }

  .accounts-pagination {
    padding: 2rem 0;
  }

  .edit-cell {
    display: none;
  }

  .data-table-container {
    border-radius: 6px;

    .data-table {
      border-radius: 1rem;
      .data-table-head {
        .data-table-row {
          .data-table-head-cell {
            height: 5rem;
            padding: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: black;
          }
          :first-child {
            .data-table-head-cell {
              :first-child {
                border-top-left-radius: 6px;
              }
              :last-child {
                border-top-right-radius: 6px;
              }
            }
          }
        }
      }

      .data-table-body {
        .data-table-row {
          border-radius: 6px;
          box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

          .account-details-iban {
            display: flex;
            align-items: center;
            gap: 1rem;
            svg:active {
              rect {
                fill: black;
              }
              filter: brightness(0) saturate(100%) invert(28%) sepia(89%) saturate(2784%) hue-rotate(216deg)
                brightness(99%) contrast(105%);
            }
          }
        }
      }

      @media (min-width: 768px) {
        .data-table-head {
          .data-table-row {
            border-bottom: 1px solid rgba(163, 184, 194, 0.3);

            .data-table-head-cell {
              padding: 0 5px;
              :first-child {
                padding-left: 2rem;
              }
              :last-child {
                padding-right: 2rem;
              }
            }
          }
        }
        .data-table-body {
          .data-table-row {
            box-shadow: none;
            border-bottom-color: #f4f6f6;

            .edit-cell {
              display: table-cell;
              span {
                width: 15px;
                height: 15px;
                display: inline-flex;
                svg {
                  display: none;
                }
              }
            }
            :hover {
              .edit-cell span svg {
                display: inline-block;
              }
            }

            .data-table-cell {
              height: 50px;
              padding: 0 5px;

              :first-child {
                padding-left: 2rem;
              }
              :last-child {
                padding-right: 2rem;
              }
            }
          }
        }
      }

      @media (min-width: 1200px) {
        .data-table-head {
          .data-table-row {
            .data-table-head-cell {
              :first-child {
                padding-left: 4rem;
              }
              :last-child {
                padding-right: 4rem;
              }
            }
          }
        }
        .data-table-body {
          .data-table-row {
            .data-table-cell {
              :first-child {
                padding-left: 4rem;
              }
              :last-child {
                padding-right: 4rem;
              }
            }
          }
        }
      }
    }
  }

  .currency-icon {
    @media (max-width: 768px) {
      margin: 0 1rem 1rem 0;
    }
  }

  .transactions-cell {
    width: 100%;
    justify-content: center;
    .button {
      font-size: 1.6rem;
      font-weight: normal;
      width: 100%;
      max-width: 24rem;
      margin: 0 auto;
    }
  }

  .data-table-body {
    background: transparent;
    border: none;
    .data-table-row {
      background: #fff;
      .data-table-cell {
        border-bottom-color: rgba(163, 177, 191, 0.15);
      }
    }
  }

  @media (min-width: 768px) {
    background: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

    .data-table-head {
      border: none;
    }
    .data-table-body {
      .data-table-row {
        background: unset;
        border-bottom: 1px solid rgba(163, 177, 191, 0.15);
        box-shadow: none;
        cursor: pointer;
        .data-table-cell.small {
          width: 15%;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    .transactions-cell {
      display: none;
    }
  }
`

export const AccountHeader = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  margin-bottom: 2rem;

  @media (min-width: 640px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  @media (min-width: 1280px) {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .left-side,
  .right-side {
    .button {
      width: auto;
      height: 4.4rem;
      //margin-bottom: 1rem;
      padding: 0 2rem 0 1.5rem;

      background: #ffffff;
      border-radius: 1rem;
      box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

      color: #454a54;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      :hover {
        color: #454a54;
        background: #ffffff;
      }

      @media (min-width: 1024px) {
        width: auto;
        //min-width: 14rem;
        margin-bottom: 0;
        justify-content: flex-start;
      }

      .icon.icon-left {
        margin-right: 1rem;
      }
    }

    /* @media (min-width: 640px) {
        width: auto;
        margin-bottom: 0;
        margin-left: 2rem;
        :first-child {
          margin-left: 0;
        }
      } */
  }

  .left-side {
    width: 100%;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 1.2rem;
    align-items: center;
    margin-bottom: 1.2rem;

    .select-wrapper {
      width: auto;
      min-width: 14.5rem;
    }

    @media (min-width: 800px) {
      justify-content: flex-start;
    }

    @media (min-width: 1280px) {
      margin-bottom: 0;
    }
  }

  .right-side {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 1.2rem;
    width: 100%;
    flex: 0;

    @media (min-width: 800px) {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
`

export const CurrencyWrapper = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

export const CurrenciesSelectStyled = {
  menu: (provided: any) => ({
    ...provided,
    border: 'none !important',
    backgroundColor: '#ffffff'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'black',
    marginLeft: '2.2rem',
    fontSize: '1.4rem',
    textTransform: 'capitalize'
  }),
  option: (provided: any, { isFocused }) => ({
    ...provided,
    fontSize: '1.4rem',
    fontWeight: '400',
    cursor: 'pointer',
    lineHeight: '2rem',
    color: isFocused ? '#ffffff' : '#000000',
    backgroundColor: isFocused ? '#000000' : 'transparent'
  }),
  container: (provided: any) => ({
    ...provided,
    height: '4.4rem',
    // borderRadius: '1rem',
    border: 'none !important',
    borderColor: 'transparent',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }),
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    height: '4.4rem',
    cursor: 'pointer',
    minHeight: 'auto',
    fontWeight: '400',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    border: 'none !important',
    borderRadius: '1rem',
    boxShadow: '0 0 25px rgb(46 53 56 / 10%)',
    paddingLeft: '1rem'
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '1.4rem',
    fontWeight: '400',
    lineHeight: '2rem',
    marginLeft: '0',
    width: '100%',
    color: '#000000'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    cursor: 'pointer'
  })
}
