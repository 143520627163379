import styled from 'styled-components'

export const TransactionsContainer = styled.div`
  [data-test='details-button'] {
    background-color: #ffffff;
    border: 1px solid rgba(180, 191, 203, 0.4);
    border-radius: 5px;

    height: 3.4rem;
    width: auto;

    color: #000000;
    font-family: inherit;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;

    @media (min-width: 1200px) {
      //width: 12rem;
      display: none;
    }
  }

  //.amount {
  //  width: 100%;
  //  display: inline-flex;
  //  justify-content: flex-end;
  //}

  .customize-columns-button {
    width: 100%;
    text-align: right;
    margin-bottom: 2rem;

    @media (min-width: 1200px) {
      display: none;
    }

    .button {
      width: auto;
      background-color: rgba(30, 99, 255, 0.34);
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

      height: 3.2rem;
      padding: 0 1.6rem 0 1rem;

      color: #ffffff;
      font-family: inherit;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4rem;
    }
  }

  .data-table-container {
    .data-table {
      .data-table-head {
        .data-table-row {
          background: none;
          border-bottom: 1px solid rgba(163, 177, 191, 0.15);

          .button {
            width: auto;
            background-color: rgba(30, 99, 255, 0.34);
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

            height: 3.2rem;
            padding: 0 1.6rem 0 1rem;

            color: #ffffff;
            font-family: inherit;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.4rem;

            margin-bottom: 1rem;
            margin-top: 1rem;
          }

          .data-table-head-cell {
            background-color: #ffffff;

            color: #000000;
            font-size: 1.3rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.4rem;

            @media (min-width: 1200px) {
              padding-left: 1rem;
              padding-right: 1rem;
            }

            :first-child {
              border-top-left-radius: 1rem;
            }
            :last-child {
              border-top-right-radius: 1rem;
            }

            @media (min-width: 1200px) {
              &.buttons {
                width: 1%;
              }
            }
          }
        }
      }

      .data-table-body {
        background-color: transparent;
        border: none;

        .data-table-row {
          background-color: #ffffff;
          box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
          border-radius: 1rem;

          .data-table-cell {
            color: #454a54;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 120%;
            border-bottom-color: rgba(163, 177, 191, 0.15);
            span {
              word-break: break-all;
            }
            //border-bottom: 1px solid rgba(163, 177, 191, 0.15);
            .details-inner {
              display: flex;
              justify-content: center;
              svg {
                cursor: pointer;
              }
            }
            &.text-upper {
              text-transform: uppercase;
            }

            .status {
              display: inline-block;
              color: #808080;

              &.status-scheduled,
              &.status-pending {
                color: #ffce32;
              }
              &.status-failed {
                color: #ff0431;
              }
              &.status-processed {
                color: #00b451;
              }
            }
            .time-container {
              display: flex;
              flex-flow: column;
              gap: 0.3rem;
            }
          }
        }
      }
    }

    @media (min-width: 1200px) {
      background-color: #ffffff;
      border-radius: 1rem;
      //box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

      .data-table {
        background-color: #ffffff;
        border-radius: 1rem 1rem 0 0;

        .data-table-head {
          .data-table-row {
            background: none;
            border-bottom: 2px solid rgba(163, 177, 191, 0.15);
            .data-table-head-cell {
              background-color: #f8f8f8;
              color: #000000;
              :first-of-type {
                padding-left: 3rem;
              }
              :last-of-type {
                padding-right: 3rem;
              }
            }
          }
        }

        .data-table-body {
          background-color: #ffffff;

          .data-table-row {
            background: none;
            border-radius: 0;
            border-bottom: 1px solid rgba(163, 177, 191, 0.15);
            &:last-child {
              border-bottom: none;
            }
            box-shadow: none;

            .data-table-cell {
              background-color: #ffffff;
              padding-left: 1rem;
              padding-right: 1rem;

              :first-of-type {
                padding-left: 3rem;
              }
              :last-of-type {
                padding-right: 3rem;
              }
            }
            .data-table-cell-caption {
              text-transform: none;
            }

            &:hover {
              [data-test='details-button'] {
                display: inline-flex;
              }
              .data-table-cell {
                background-color: rgba(163, 177, 191, 0.07);
                border-radius: 0;
              }
            }

            :last-child {
              border-bottom: none;
              .data-table-cell {
                :first-child {
                  border-bottom-left-radius: 1rem;
                }
                :last-child {
                  border-bottom-right-radius: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
`
