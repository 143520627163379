import { useState } from 'react'

import cn from 'classnames'

import { TotalsContainer, TotalsContent, TotalsHeader, TotalsSectionStyled } from './TotalsSection.styled'

import { formatMoney, tr } from 'mmfintech-commons'
import { selectMerchantCurrency, useAppSelector, useGetCheckoutStatsQuery } from 'mmfintech-backend-api'

export const TotalsSection = () => {
  const [activeTab, setActiveTab] = useState<string>('month')

  const merchantCurrency = useAppSelector(selectMerchantCurrency)
  const { data: checkoutStats, isFetching: checkoutStatsFetching } = useGetCheckoutStatsQuery()

  const { paymentData, payoutData } = checkoutStats || {}

  const getPaymentVolume = () => {
    const { monthlyVolume, yearlyVolume } = paymentData || {}
    return activeTab === 'month' ? monthlyVolume : yearlyVolume
  }

  const getTransactionsCount = () => {
    const { monthlyCount, yearlyCount } = paymentData || {}
    return activeTab === 'month' ? monthlyCount : yearlyCount
  }

  const getPayoutVolume = () => {
    const { monthlyVolume, yearlyVolume } = payoutData || {}
    return activeTab === 'month' ? monthlyVolume : yearlyVolume
  }

  if (!checkoutStatsFetching) {
    return (
      <TotalsSectionStyled>
        <TotalsHeader>
          <span
            onClick={() => setActiveTab('month')}
            className={cn({ active: activeTab === 'month' })}
            data-test='filter-this-month'>
            {tr('FRONTEND.DASHBOARD.THIS_MONTH', 'This Month')}
          </span>
          <span
            onClick={() => setActiveTab('year')}
            className={cn({ active: activeTab === 'year' })}
            data-test='filter-this-year'>
            {tr('FRONTEND.DASHBOARD.THIS_YEAR', 'This Year')}
          </span>
        </TotalsHeader>

        <TotalsContainer>
          <TotalsContent>
            <div className='icon payments' />
            <div className='total'>
              <span className='label'>{tr('FRONTEND.DASHBOARD.PAYMENTS', 'Payments')}</span>
              {formatMoney(getPaymentVolume() || 0, merchantCurrency)}
            </div>
          </TotalsContent>
          <TotalsContent>
            <div className='icon transactions' />
            <div className='total'>
              <span className='label'>{tr('FRONTEND.DASHBOARD.PAYMENT_TRANSACTIONS', 'Payment Transactions')}</span>
              {getTransactionsCount()}
            </div>
          </TotalsContent>
          <TotalsContent>
            <div className={cn('icon', 'payouts', merchantCurrency)} />
            <div className='total'>
              <span className='label'>{tr('FRONTEND.DASHBOARD.PAYOUTS', 'Payouts')}</span>
              {formatMoney(getPayoutVolume() || 0, merchantCurrency)}
            </div>
          </TotalsContent>
        </TotalsContainer>
      </TotalsSectionStyled>
    )
  }

  return null
}
