import styled from 'styled-components'

export const LimitsWrapper = styled.div`
  background: #ffffff;
  [data-test='details-button'] {
    background: #000000;
    border-radius: 5px;
    height: 3.5rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 120%;

    @media (min-width: 1200px) {
      display: none;
      max-width: 9rem;
      height: 3rem;
    }
  }

  @media (min-width: 1000px) {
    .data-table-container {
      border-radius: 8px;
      .data-table {
        border-radius: 8px;
        .data-table-head {
          .data-table-row {
            .data-table-head-cell {
              &:first-of-type {
                padding-left: 5rem;
              }
            }
          }
        }
      }
    }

    .data-table-body {
      .data-table-row {
        .data-table-cell {
          height: 5rem;
          &:first-of-type {
            padding-left: 5rem;
          }
          &:last-of-type {
            padding-right: 2rem;
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
    box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
    .data-table {
      .data-table-head {
        .data-table-row {
          border-bottom: 2px solid rgba(163, 177, 191, 0.15);
          color: #000000;
          .data-table-head-cell {
            background-color: #f8f8f8;
            height: 5rem;
          }
        }
      }
      .data-table-body {
        .data-table-row {
          cursor: pointer;
        }
      }
    }
    .data-table-head-cell.small,
    .data-table-cell.small {
      width: 10%;
    }
  }

  .data-table-cell,
  .data-table-head-cell {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .data-table-head {
    .data-table-row {
      .data-table-head-cell {
        border-bottom: 1px solid rgba(163, 184, 194, 0.3);

        color: #85a0ad;
        font-size: 1.2rem;
        line-height: 1.5rem;
        font-weight: 400;

        text-transform: capitalize;
      }

      .button {
        background: #4f4f4f;
        border-radius: 5px;

        height: 3rem;
        padding: 0 1.8rem 0 1rem;

        color: #eeeeee;
        font-family: inherit;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        margin-bottom: 1rem;
      }
    }
  }

  @media (min-width: 1200px) {
    .thin,
    .buttons {
      width: 1%;
    }
  }

  .data-table {
    .data-table-body {
      background: transparent;
      .data-table-row {
        background: transparent;
        box-shadow: ${({ theme }) => theme.dataTable.body.dropShadow};
        border-bottom: none;
        [data-test='description'] {
          line-break: anywhere;
        }
        [data-test='amount'] {
          white-space: pre;
        }
        :hover {
          background-color: rgba(163, 184, 194, 0.1);
          [data-test='details-button'] {
            display: inline-flex;
          }
          .recipient-details {
            visibility: visible;
          }
        }

        .data-table-cell {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.5rem;
          &.recipient-cell {
            max-width: 3rem;
          }

          .limit-name {
            width: 30rem;
          }
        }
        .data-table-cell-caption {
          text-transform: none;
        }
      }
    }

    .view-button-cell {
      .button {
        width: 100%;
      }
    }

    @media (min-width: 1200px) {
      .data-table-body {
        .data-table-row {
          background: #ffffff;
          box-shadow: none;
        }
      }

      .view-button-cell {
        display: none;
      }

      .data-table-cell,
      .data-table-head-cell {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .attachment-cell {
        width: 4rem;
        .attachment-text {
          display: none;
        }
      }

      .attachment-cell,
      .arrow-right-cell {
        padding-left: 0;
        padding-right: 0;
      }

      .from-to {
        align-items: flex-start;
      }
    }

    .recipient-details {
      border: 1px solid rgba(133, 160, 173, 0.5);
      border-radius: 5px;
      margin-left: 1rem;
      height: 3rem;

      text-decoration: none;

      font-size: 1rem;
      line-height: 120%;

      @media (min-width: 1200px) {
        width: 6rem;
        height: 2.4rem;
        visibility: collapse;
      }
    }
    .recipient-wrapper {
      @media (max-width: 768px) {
        width: auto;
        display: block;
      }
    }

    .action-buttons {
      text-align: center;
      gap: 1.5rem;

      @media (min-width: 1200px) {
        width: 300px;
      }

      button {
        width: 9rem;
        height: 3rem;
        padding: 1.5rem 3rem;
      }

      @media (max-width: 768px) {
        margin-top: 1rem;
        border-bottom: none;
        width: 100%;
      }

      .approve {
        background: #ffffff;
        color: #44cc00;
        border: 1px solid rgba(68, 204, 0, 0.7);
        border-radius: 5px;
        margin-right: 5px;
        @media (max-width: 450px) {
          width: 100%;
        }
      }

      .reject {
        background: #ffffff;
        color: #ff3131;
        border: 1px solid rgba(255, 49, 49, 0.5);
        border-radius: 5px;
        margin-left: 5px;

        @media (max-width: 768px) {
          margin-left: 0px;
        }

        @media (max-width: 450px) {
          width: 100%;
        }
      }

      .status {
        width: 100%;
      }
    }

    .icon {
      margin-right: 0.5rem;
    }
  }
`
