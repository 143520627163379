import styled from 'styled-components'

export const StatusSwitchStyled = styled.div`
  width: 50px;
  height: 30px;
  border-radius: 30px;
  background: rgba(255, 76, 77, 0.7);
  padding: 0 2px;

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .ball {
    width: 26px;
    height: 26px;
    display: inline-block;
    background: #ffffff;
    border-radius: 50%;
  }

  &.active {
    background: rgba(74, 209, 27, 0.7);
    flex-flow: row-reverse nowrap;
  }

  &.clickable {
    cursor: pointer;
  }
`
