import styled from 'styled-components'

export const FilterWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr auto;
  }
`

export const FilterButtons = styled.div`
  grid-row: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .button {
    background-color: #ff4c4d;
    border-radius: 1rem;

    color: #ffffff;
    font-family: inherit;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7rem;
    height: 4.4rem;

    .icon {
      svg {
        height: 20px;
        width: 20px;
      }
    }
    @media (max-width: 372px) {
      margin-bottom: 8px;
    }

    &.button-delete {
      background-color: rgba(180, 191, 203, 0.8);
      &:hover,
      &:focus {
        color: #ffffff;
      }
    }
  }

  @media (min-width: 640px) {
  }

  @media (min-width: 1024px) {
    justify-content: flex-end;
    padding-top: 2rem;
    grid-row: auto;
  }
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  .button {
    background-color: #ff4c4d;
    border-radius: 1rem;
    margin-top: 1.9rem;
    color: #ffffff;
    font-family: inherit;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7rem;
    height: 4.4rem;

    .icon {
      svg {
        height: 20px;
        width: 20px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(325deg) brightness(101%)
          contrast(102%);
      }
    }
    @media (max-width: 372px) {
      margin-bottom: 8px;
    }

    &.button-delete {
      background-color: rgba(180, 191, 203, 0.8);
      &:hover,
      &:focus {
        color: #ffffff;
      }
    }
    &.apply-button {
      background-color: rgb(0, 180, 81);
      &:hover,
      &:focus {
        background-color: rgb(0, 200, 30);
      }
    }
  }
  .input-wrapper {
    margin-bottom: 2rem;
    margin-right: 1.5rem;

    & > div {
      border: none;
      box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
    }

    label {
      color: #454a54;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.4rem;
      margin-bottom: 0.5rem;
      padding-left: 0;
    }

    input {
      height: 4.4rem;
      font-size: 1.4rem;
      font-weight: 400;

      &::-webkit-input-placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
      &:-ms-input-placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
      &::placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
    }
  }

  [data-test='date-input-from'],
  [data-test='date-input-to'] {
    max-width: 12rem;

    & > div {
      padding-left: 1.5rem;
      padding-right: 1rem;
    }
  }

  [data-test='number-input-id'] {
    max-width: 10rem;
  }
  [data-test='text-input-foreignTransactionId'] {
    max-width: 14rem;
  }
  [data-test='text-input-reference'] {
    max-width: 14rem;
  }
  [data-test='text-input-customerEmail'],
  [data-test='text-input-description'] {
    max-width: 22rem;
  }
  [data-test='text-input-toAmount'],
  [data-test='text-input-fromAmount'] {
    max-width: 14rem;
  }
  [data-test='select-input-statuses'] {
    width: auto;
    min-width: 12rem;
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
  [data-test='select-input-currency'] {
    max-width: 10rem;
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
  [data-test='select-input-paymentMethods'] {
    width: auto;
    min-width: 20rem;
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

// noinspection JSUnusedGlobalSymbols
export const FilterSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
    fontFamily: 'inherit',
    fontSize: '1.4rem',
    lineHeight: '1.7rem'
  }),
  control: (provided: any, { menuIsOpen }) => ({
    ...provided,
    cursor: 'pointer',
    color: menuIsOpen ? '#000000' : '#ffffff',
    borderWidth: '1px',
    borderStyle: 'none',
    borderRadius: '1rem',
    minHeight: '4.4rem',
    //paddingLeft: '1rem',
    padding: '0 0 0 1rem',
    transition: 'none',
    boxShadow: 'none',
    outline: 'none'
  }),
  multiValue: (provided: any, { menuIsOpen }) => ({
    ...provided,
    backgroundColor: 'rgba(30, 99, 255, 0.5)',
    borderRadius: '3px',
    color: menuIsOpen ? '#000000' : '#ffffff'
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: 'inherit'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    color: '#000000',
    padding: '0 8px'
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: '0 8px 0 0'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0 8px 0 0',
    color: '#000000'
  }),
  singleValue: (provided: any) => ({
    ...provided
  }),
  input: (provided: any) => ({
    ...provided,
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: 0
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#808080',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '120%'
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#ffffff',
    border: 'none !important',
    borderRadius: '1rem',
    margin: '1rem 0 0 0',
    padding: '1rem 0',
    boxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)',
    ':hover': {
      border: 'none !important'
    }
  }),
  option: (provided: any) => ({
    ...provided,
    color: '#000000',
    fontSize: '1.4rem',
    lineHeight: '120%',
    cursor: 'pointer',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    //marginTop: '5px',
    padding: '1rem 2rem',
    ':hover': {
      backgroundColor: '#e7ebee'
    }
  })
}

export const CheckBoxGroupContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #e1e5ea;
  border-radius: 10px;
  padding: 2rem 3rem;
`
