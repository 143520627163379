import styled from 'styled-components'

import LanguageIcon from '../../images/icons/language.svg'

export const MenuWrapper = styled.div`
  position: relative;

  color: #454a54;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;

  &.hamburger {
    //height: 3.6rem;
    padding: 1.6rem 0;
  }
`

export const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  min-width: 16rem;
  height: 3.6rem;

  .icon {
    &.additional {
      &:after {
        content: '';
        width: 22px;
        height: 22px;
        //prettier-ignore
        background: url("${LanguageIcon}") center no-repeat;
        background-size: cover;
      }
    }
    display: flex;
    margin: 0 1rem 0 1.2rem;
  }

  .selected {
    width: 100%;
  }

  .arrow {
    width: 11px;
    margin: 0 1.2rem 0 1rem;
  }

  &.bordered {
    border-radius: 1rem;
    border: 1px solid rgba(163, 177, 191, 0.5);
    height: 5rem;
  }
`

export const MenuDropDown = styled.div`
  z-index: 1001;
  position: absolute;
  top: 4.5rem;

  width: 16rem;
  padding: 1rem 0;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  display: none;
  &.opened {
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 4rem;
      padding: 0 1rem;
      color: #454a54;

      :hover {
        background: #e7ebee;
      }
      .icon {
        display: flex;
        margin: 0 1rem;
      }
    }
  }
`
