import styled from 'styled-components'

export const CurrencyIconWrapper = styled.span<{ size?: string }>`
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #eee;
  border-radius: 5px;

  & > img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }

  &.circle {
    border-radius: 50%;

    & > img {
      border-radius: 50%;
    }
  }
`
