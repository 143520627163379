import { useContext } from 'react'

import { toast } from 'react-hot-toast'

import { Button } from 'mmfintech-portal-commons'
import { GlobalContext, tr } from 'mmfintech-commons'
import { AccountCreateModal } from '../AccountCreateModal'
import { NoAvailableAccountsWrapper } from './NoAvailableAccounts.styled'

import NoAccountsIcon from '../../../images/icons/no-accounts-icon.svg?react'
import AddAccountIcon from '../../../images/icons/add-account-icon.svg?react'

interface NoAvailableAccountsProps {
  currency: string
}

export const NoAvailableAccounts = ({ currency }: NoAvailableAccountsProps) => {
  const { modalShow }: any = useContext(GlobalContext)
  const handleAddAccount = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto', overflow: 'none' },
      content: (
        <AccountCreateModal
          filteredCurrencies={[currency]}
          onSuccess={(currencyCode: string) => {
            toast.remove()
            toast.success(
              tr('FRONTEND.ACCOUNTS.SUCCESS_MESSAGE', '{{CURRENCY}} account successfully added', {
                CURRENCY: currencyCode
              })
            )
          }}
        />
      )
    })
  }
  return (
    <NoAvailableAccountsWrapper>
      <div className='left'>
        <NoAccountsIcon />
        <div>
          {tr('FRONTEND.ACCOUNTS.NO_ACCOUNTS_FOR_CURRENCY.TITLE', 'No available accounts in the currency you choose.')}
        </div>
      </div>
      <Button
        icon={<AddAccountIcon />}
        text={tr('FRONTEND.ACCOUNTS.NO_ACCOUNTS_FOR_CURRENCY.CREATE_BUTTON', 'Add new account')}
        onClick={handleAddAccount}
        color='round-secondary'
      />
    </NoAvailableAccountsWrapper>
  )
}
